import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "../../redux/slices/authSlice";
import API from "../../utils/axiosInstance";

const VerifyEmail = ({ show, onHide, data }) => {
  const intervalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    startInterval();
  }, []);

  const handleSubmit = (e) => {
    const tempData = { ...data };
    e.preventDefault();

    // remove data.confirmPassword
    delete tempData.confirmPassword;
    API.post("/auth/register", { ...tempData, otp }).then((res) => {
      dispatch(login(res.data));
      navigate("/");
      onHide();
    });
  };

  const startInterval = () => {
    if (intervalRef.current) return;
    setTimer(60);
    intervalRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(intervalRef.current);
  };

  const resendOtp = () => {
    API.post("/auth/sendVerifyOtp", {
      email: data.email,
      username: data.username,
    }).then((res) => {
      startInterval();
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Verify Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <p>Please enter the verification code sent to your email</p>
          <div className="my-3 d-none search-box s_input d-md-flex align-items-center">
            <input
              type="number"
              placeholder="e.g: 123456"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          {timer > 0 ? (
            <p>Resend after {timer}s</p>
          ) : (
            <p>
              Didn't received yet?{" "}
              <span className="text-primary cursor-pointer" onClick={resendOtp}>
                Resend
              </span>
            </p>
          )}

          <button type="submit" className="btn2 w-100">
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyEmail;
