import axios from "axios";
import store from "../redux/store";
import { logout } from "../redux/slices/authSlice";
import { toast } from "react-toastify";

// Create an Axios instance
const API = axios.create({
  baseURL: process.env.REACT_APP_API_HOST + "/v1", // Replace with your API base URL
});

// Request interceptor
API.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const token = state.auth?.tokens?.access?.token;

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Attach token to Authorization header
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (response?.data?.message) {
      toast.success(response?.data?.message);
    }
    return response;
  },
  (error) => {
    // throw error;
    toast.error(error?.response?.data?.message);
    if (error?.response?.status === 401) {
      // Handle unauthorized errors (e.g., log the user out)
      store.dispatch(logout()); // Dispatch a logout if token is invalid
    }

    return Promise.reject(error);
  }
);

export default API;
