import React from "react";
import SettingLayout from "../../Components/SettingLayout";
import { MdKeyboardArrowDown } from "react-icons/md";
import paypallImg from "../../assets/paypall.png";
import { MdOutlinePayment } from "react-icons/md";

const SubscriptionBilling = () => {
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Subscription and Billing </h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <MdOutlinePayment />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Payment Method
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">Select</label>
              <img src={paypallImg} alt="paypall" className="paypall" />
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <MdOutlinePayment />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Billing History
              </label>
            </div>

            <div className="mt-1">
              <table class="table custome_table">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Plan</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row"> Feb 2022</td>
                    <td> USD $60.73</td>
                    <td>Standard</td>
                    <td className="primary-color fw-bold">Paid</td>
                  </tr>
                  <tr>
                    <td scope="row"> Jan 2022</td>
                    <td> USD $60.73</td>
                    <td>Standard</td>
                    <td className="primary-color fw-bold">Paid</td>
                  </tr>
                  <tr>
                    <td scope="row"> Dec 2022</td>
                    <td> USD $60.73</td>
                    <td>Standard</td>
                    <td className="primary-color fw-bold">Paid</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <MdOutlinePayment />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Subscription
              </label>
            </div>
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit ">
              <label className="cursor-pointer   w-100 " htmlFor="radio1">
                Standard Subscription
              </label>
              <div>
                <input
                  type="radio"
                  name="radio"
                  id="radio1"
                  className=" cursor-pointer "
                  checked
                />
              </div>
            </div>{" "}
            <div className="search-box w-100 d-md-flex align-items-center my-4 profile-edit ">
              <label className="cursor-pointer w-100 " htmlFor="radio2">
                Gold Subscription
              </label>
              <div>
                <input
                  type="radio"
                  name="radio"
                  id="radio2"
                  className=" cursor-pointer "
                />
              </div>
            </div>
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit ">
              <label className="cursor-pointer w-100 " htmlFor="radio3">
                Platinum Subscription
              </label>
              <div>
                <input
                  type="radio"
                  name="radio"
                  id="radio3"
                  className=" cursor-pointer "
                />
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default SubscriptionBilling;
