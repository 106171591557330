import { useSelector } from "react-redux";
import { AiOutlineBars } from "react-icons/ai";
import { HiOutlineGif } from "react-icons/hi2";
import { IoImageOutline } from "react-icons/io5";
import { LuCalendarDays } from "react-icons/lu";
import { CiFaceSmile, CiLocationOn } from "react-icons/ci";
import { useRef, useState } from "react";
import API from "../utils/axiosInstance";

const HeadPostContainer = () => {
  const inputRef = useRef();
  const { user } = useSelector((state) => state.auth);
  const [caption, setCaption] = useState("");
  const [media, setMedia] = useState(null);
  const [prevMedia, setPrevMedia] = useState(null);

  const handleMedia = (e) => {
    setMedia(e.target.files[0]);
    setPrevMedia(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!media && !caption) return;
    let fd = { caption };
    // Handle form submission
    if (media) {
      fd = new FormData();
      fd.append("caption", caption);
      fd.append("file", media);
    }
    API.post("/post", fd).then((res) => {
      setCaption("");
      setMedia(null);
      setPrevMedia(null);
    });
  };

  return (
    <form onSubmit={handleSubmit} className="p-3 custom-shadow mt-3">
      <div className="d-flex align-items-center gap-2">
        <div className="green_line"></div>
        <p className="primary-text fw-bold">New Post</p>
      </div>
      <div className="border-bottom-gray  d-flex gap-2 mt-3 ">
        <div className="profile">
          <img src={user?.profile} alt="" />
        </div>
        <div className="mt-2 w-100">
          <textarea
            type="text"
            placeholder="What is happening?!"
            className="w-100 post_input"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
          {prevMedia && (
            <img className="w-100 head-post-img mb-2" src={prevMedia} alt="" />
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex align-items-center gap-2">
          <IoImageOutline
            className="icon cursor-pointer"
            onClick={() => inputRef.current.click()}
          />
          <HiOutlineGif className="icon" />
          <AiOutlineBars className="icon" />
          <CiFaceSmile className="icon" />
          <LuCalendarDays className="icon" />
          <CiLocationOn className="icon" />
          <input
            ref={inputRef}
            type="file"
            onChange={handleMedia}
            className="d-none"
          />
        </div>
        <button type="submit" className="btn2">
          Post
        </button>
      </div>
    </form>
  );
};

export default HeadPostContainer;
