import React from "react";
import SettingSidebar from "./SettingSidebar";
import Layout from "./Layout";

const SettingLayout = ({ children }) => {
  return (
    <Layout>
      <div className="setting-layout d-flex gap-4 container my-5  ">
        <SettingSidebar />
        <div className="mt-3 w-100 ">{children}</div>
      </div>
    </Layout>
  );
};

export default SettingLayout;
