import React from "react";
import numeral from "numeral";

const NumberFormatter = ({ number }) => {
  const formattedNumber = numeral(number).format("0a").toUpperCase();

  return <div>{formattedNumber}</div>;
};

export default NumberFormatter;
