import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import { IoIosSearch, IoMdHome } from "react-icons/io";
import { GoBell } from "react-icons/go";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TbAd, TbMenu2, TbSettings, TbUserEdit } from "react-icons/tb";
import { VscSignOut } from "react-icons/vsc";
import { useEffect, useMemo, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import API from "../utils/axiosInstance";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";
import {
  IoChatboxEllipsesOutline,
  IoChatboxEllipsesSharp,
} from "react-icons/io5";
import { PiUsersThree } from "react-icons/pi";
import { FaRegMessage } from "react-icons/fa6";
import { socket } from "../utils/socket";
import { toast } from "react-toastify";
import SearchResults from "./SearchResults";

const LIMIT = 5;

const Navbar = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated, tokens } = useSelector(
    (states) => states.auth
  );
  const nodeRef = useRef();
  const divRef = useRef();
  const notificationsRef = useRef();
  const notificationsDivRef = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);

  const isUnreadNotification = useMemo(() => {
    const filtered = notifications.filter(
      (notification) => notification.unread === true
    );

    return filtered.length > 0;
  }, [notifications]);

  const handleScroll = (e) => {
    const bottom =
      parseInt(e.target.scrollHeight - e.target.scrollTop) ===
      e.target.clientHeight;

    if (bottom) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    const notificationContainer = notificationsRef.current;
    if (notificationContainer) {
      notificationContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (notificationContainer) {
        notificationContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [notificationsRef.current, showNotification]);

  useEffect(() => {
    if (isAuthenticated) {
      API.get(`/user/notifications?limit=${LIMIT}&page=${page}`).then((res) => {
        setNotifications([...notifications, ...res.data.results]);
      });
    }
  }, [isAuthenticated, page]);

  useEffect(() => {
    // Add event listener
    document.addEventListener("mousedown", (e) => {
      if (divRef.current && !divRef.current.contains(e.target)) {
        setShowMenu(false);
      }

      if (
        notificationsDivRef.current &&
        !notificationsDivRef.current.contains(e.target)
      ) {
        setShowNotification(false);
      }
    });

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", (e) => {
        if (divRef.current && !divRef.current.contains(e.target)) {
          setShowMenu(false);
        }
        if (
          notificationsDivRef.current &&
          !notificationsDivRef.current.contains(e.target)
        ) {
          setShowNotification(false);
        }
      });
    };
  }, []);

  const handleLogout = () => {
    API.post("/auth/logout", { refreshToken: tokens?.refresh?.token }).then(
      (res) => {
        dispatch(logout());
        navigate("/");
      }
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      socket.emit("getUnreads");
      socket.on("unreadConversations", (count) => {
        setUnreadMessages(count);
      });
      if (!pathname.includes("/chat")) {
        socket.on("receiveMessage", (data) => {
          toast.info(
            `You received a new message from ${data?.sender?.firstName}`
          );
        });
      }

      return () => {
        socket.off("unreadConversations");
        socket.off("receiveMessage");
      };
    }
  }, [isAuthenticated, socket]);

  const handleNotificationClick = (notification) => {
    socket.emit("updateNotificationStatus", notification.id);
    if (notification.type === "message") {
      navigate("/chat");
    }
  };

  return (
    <nav className="nav py-4">
      <div className="container d-flex justify-content-between align-items-center w-100">
        <Link to={"/"} className="logo">
          <img src={logo} alt="Backyard" />
        </Link>
        <SearchResults />
        {isAuthenticated && (
          <div className="d-flex align-items-center gap-2 gap-sm-3">
            <Link to={"/"}>
              {pathname === "/" ? (
                <AiFillHome size={22} />
              ) : (
                <AiOutlineHome size={22} />
              )}
            </Link>
            <PiUsersThree size={22} />
            <Link to={"/chat"} className="position-relative">
              {pathname.includes("/chat") ? (
                <IoChatboxEllipsesSharp size={22} />
              ) : (
                <IoChatboxEllipsesOutline size={22} />
              )}
              {unreadMessages > 0 && <div className="red-dot"></div>}
            </Link>
            <div className="position-relative" ref={notificationsDivRef}>
              <GoBell
                size={22}
                className="cursor-pointer"
                onClick={() => setShowNotification(!showNotification)}
              />
              {isUnreadNotification && <div className="red-dot"></div>}
              <CSSTransition
                in={showNotification}
                timeout={300}
                nodeRef={notificationsRef}
                classNames="profile-menu notifications"
                unmountOnExit
              >
                <div
                  ref={notificationsRef}
                  className="profile-menu notifications p-0"
                >
                  <div className="d-flex align-items-center gap-2 pb-2 border-bottom p-2 position-sticky top-0 bg-white">
                    <GoBell size={20} />
                    <h6 className="m-0">
                      Notifications ({notifications.length})
                    </h6>
                  </div>
                  {notifications.map((notification, i) => (
                    <div
                      key={i}
                      className={`d-flex align-items-center gap-2 cursor-pointer notification-box p-2 ${
                        !notification.unread ? "bg-light-gray" : ""
                      }`}
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="notification-left col-3">
                        <FaRegMessage size={16} />
                      </div>
                      <p className="m-0">{notification.text}</p>
                    </div>
                  ))}
                </div>
              </CSSTransition>
            </div>
            <div className="divider"></div>
            <div className="position-relative" ref={divRef}>
              <div
                onClick={() => setShowMenu(!showMenu)}
                className="profile d-flex gap-1 align-items-center cursor-pointer"
              >
                <img src={user?.profile} alt="" />
                <MdKeyboardArrowDown size={22} />
              </div>
              <CSSTransition
                in={showMenu}
                timeout={300}
                nodeRef={nodeRef}
                classNames="profile-menu"
                unmountOnExit
              >
                <div ref={nodeRef} className="profile-menu">
                  <Link
                    to={"/profile"}
                    className="d-flex topic-profile align-items-center gap-2"
                  >
                    <img src={user?.profile} alt="" />
                    <div>
                      <p className="small-text">View Profile</p>
                      <p className="smaller-text">
                        {user?.firstName} {user?.lastName}
                      </p>
                    </div>
                  </Link>
                  <div className="mt-3 d-flex flex-column gap-3">
                    <p className="primary-text gray-color d-flex align-items-center gap-2 cursor-pointer">
                      <TbUserEdit size={18} /> Edit Profile
                    </p>
                    <p
                      onClick={handleLogout}
                      className="primary-text gray-color d-flex align-items-center gap-2 cursor-pointer"
                    >
                      <VscSignOut size={18} /> Log Out
                    </p>
                    <Link
                      to={"/settings"}
                      className="primary-text gray-color d-flex align-items-center gap-2 cursor-pointer"
                    >
                      <TbSettings size={18} /> Settings
                    </Link>
                    <p className="primary-text gray-color d-flex align-items-center gap-2 cursor-pointer">
                      <TbAd size={18} /> Advertise on Backyard
                    </p>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </div>
        )}

        {!isAuthenticated && (
          <>
            <div className="d-flex align-items-center gap-3 d-none d-sm-flex">
              <Link to={"/login"} className="btn1">
                Login
              </Link>
              <Link to={"/signup"} className="btn2">
                Signup
              </Link>
            </div>
            <div className="position-relative d-sm-none" ref={divRef}>
              <div className="menu-bars" onClick={() => setShowMenu(!showMenu)}>
                <TbMenu2 size={22} />
              </div>
              <CSSTransition
                in={showMenu}
                timeout={300}
                nodeRef={nodeRef}
                classNames="profile-menu"
                unmountOnExit
              >
                <div ref={nodeRef} className="profile-menu">
                  <div className="d-flex flex-column gap-2">
                    <Link to={"/login"} className="btn1 text-center">
                      Login
                    </Link>
                    <Link to={"/signup"} className="btn2 text-center">
                      Signup
                    </Link>
                  </div>
                </div>
              </CSSTransition>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
