import { useEffect, useRef, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { CSSTransition } from "react-transition-group";

const Dropdown = ({ label, items, value, onChange }) => {
  const [showMenu, setShowMenu] = useState(false);
  const divRef = useRef();
  const outerRef = useRef();
  useEffect(() => {
    // Add event listener
    document.addEventListener("mousedown", (e) => {
      if (
        divRef.current &&
        !divRef.current.contains(e.target) &&
        !outerRef.current.contains(e.target)
      ) {
        setShowMenu(false);
      }
    });

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", (e) => {
        if (
          divRef.current &&
          !divRef.current.contains(e.target) &&
          !outerRef.current.contains(e.target)
        ) {
          setShowMenu(false);
        }
      });
    };
  }, []);

  return (
    <div
      ref={outerRef}
      className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit position-relative"
      onClick={() => setShowMenu(!showMenu)}
    >
      <label className="cursor-pointer  w-100 ">{value || label}</label>
      <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
      <CSSTransition
        in={showMenu}
        timeout={300}
        nodeRef={divRef}
        classNames="profile-menu"
        unmountOnExit
      >
        <div
          ref={divRef}
          className="position-absolute top-100 mt-2 w-100 dropdown-items"
        >
          <ul className="list-group list-group-flush">
            {items.map((item) => (
              <li
                onClick={() => onChange(item.value)}
                className="list-group-item"
              >
                {item.label}
              </li>
            ))}
          </ul>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Dropdown;
