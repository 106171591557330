import { createSlice } from "@reduxjs/toolkit";

const globalState = {
  isLoading: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState: globalState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    endLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const { startLoading, endLoading } = globalSlice.actions;

export default globalSlice.reducer;
