import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const MessagesBox = ({ messages }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const bottomRef = useRef();
  const [newMessages, setNewMessages] = useState([]);

  useEffect(() => {
    if (messages.length) {
      setNewMessages(messages);
    }
  }, [messages]);

  useEffect(() => {
    if (newMessages.length)
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [newMessages]);

  return (
    <div
      className="mt-4 flex-basis-1"
      style={{ overflowX: "hidden", overflowY: "auto" }}
    >
      {newMessages?.map((message, index) => {
        if (currentUser.id == message?.sender?.id) {
          return (
            <div className="row mb-3">
              <div className="col-1"></div>
              <div className="col-10">
                <div
                  className="py-2 px-3"
                  style={{ backgroundColor: "#ecf9fd" }}
                >
                  <p className="mb-0 fw-bold">
                    {currentUser?.firstName} {currentUser?.lastName}
                  </p>
                  <p
                    className="mb-0 primary-text message_box"
                    style={{
                      color: "#747474",
                      fontWeight: 400,
                    }}
                  >
                    {message?.message}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3 mt-1 justify-content-end">
                  <span className="continue fw-semibold">1d</span>
                  <span className="continue fw-semibold">Like</span>
                  <span className="continue fw-semibold">Reply</span>
                </div>
              </div>
              <div className="col-1 p-0">
                <img
                  style={{
                    width: "2.2rem",
                    height: "2.2rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={currentUser?.profile}
                  alt="Your profile"
                />
              </div>
            </div>
          );
        } else
          return (
            <div key={index} className="row mb-4">
              <div className="col-1">
                <img
                  className="col-3"
                  style={{
                    width: "2.2rem",
                    height: "2.2rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                  src={message?.sender?.profile}
                  alt={message?.sender?.firstName}
                />
              </div>
              <div className="col-10">
                <div className="bg-light-gray py-2 px-3">
                  <p className="fw-bold mb-0">
                    {message?.sender?.firstName} {message?.sender?.lastName}
                  </p>
                  <p
                    className="mb-0 primary-text"
                    style={{
                      color: "#747474",
                      fontWeight: 400,
                    }}
                  >
                    {message?.message}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-3 mt-1">
                  <span className="continue fw-semibold">1d</span>
                  <span className="continue fw-semibold">Like</span>
                  <span className="continue fw-semibold">Reply</span>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          );
      })}
      <div key={newMessages.length} ref={bottomRef}></div>
    </div>
  );
};

export default MessagesBox;
