import React, { useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

const FAQs = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className=" profile-edit mb-3">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h4 className="accordion-question">{question}</h4>
        <div className="accordion-icon">
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
      </div>
      <div
        className={`accordion-content ${isOpen ? "open" : ""}`}
        style={{
          maxHeight: isOpen ? "300px" : "0",
          transition: "max-height 0.3s ease",
        }}
      >
        <p>{answer}</p>
      </div>
    </div>
  );
};

export default FAQs;
