import React, { useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { GoPencil } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../redux/slices/authSlice";
import TwoFactorModal from "../../Components/Modals/TwoFactorModal";
import API from "../../utils/axiosInstance";

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState("");
  const [twoFactorModal, setTwoFactorModal] = useState(false);

  const updateEmail = () => {
    API.post("/user/updateEmail", { email }).then((res) => {
      dispatch(updateData({ email: res.data?.email }));
    });
  };

  const updatePassword = () => {
    API.post("/user/updatePassword", { password }).then((res) => {
      setPassword("");
    });
  };

  const toggleTwoFactor = () => {
    API.post("/auth/2fa/toggle").then((res) => {
      if (res.data.success) {
        dispatch(updateData({ twoFactorEnabled: !user.twoFactorEnabled }));
      } else {
        setTwoFactorModal(true);
      }
    });
  };

  return (
    <SettingLayout>
      {twoFactorModal && (
        <TwoFactorModal
          show={twoFactorModal}
          onHide={() => setTwoFactorModal(false)}
        />
      )}
      <div className="profile-settings">
        <h3 className="primary-heading">Account Settings</h3>
        <div className="my-4">
          <div className="d-flex align-items-center gap-3 mb-4">
            <div class=" w-100 ">
              <div className="d-flex align-items-center gap-2 ms-2">
                <GoPencil />
                <label for="email" class="form-label small-text ">
                  Edit Email
                </label>
              </div>
              <div className="d-flex w-100 mt-1 profile-edit">
                <input
                  type="email"
                  className="w-100 border-0 outline-0 py-1"
                  id="email"
                  placeholder="Email Address..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email !== user.email && (
                  <button
                    onClick={updateEmail}
                    className="btn2 py-1 px-2 ms-2"
                    style={{ fontSize: "13px" }}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>{" "}
            <div class=" w-100 ">
              <div className="d-flex align-items-center gap-2 ms-2">
                <GoPencil />
                <label for="password" class="form-label small-text ">
                  Edit Password
                </label>
              </div>

              <div className="d-flex w-100 mt-1 profile-edit">
                <input
                  type="password"
                  className="w-100 border-0 outline-0 py-1"
                  id="password"
                  placeholder="New Password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password !== "" && (
                  <button
                    className="btn2 py-1 px-2 ms-2"
                    style={{ fontSize: "13px" }}
                    onClick={updatePassword}
                  >
                    Update
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <GoPencil />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Two Factor Authentication
              </label>
            </div>
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer w-100">
                Two Factor Authentication
              </label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={toggleTwoFactor}
                    checked={user?.twoFactorEnabled}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
        </div>
      </div>
    </SettingLayout>
  );
};

export default Account;
