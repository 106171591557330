import React, { useState } from "react";
import logo from "../assets/fullWhite-logo.png";
import { SlEnvolope } from "react-icons/sl";
import { CiLock } from "react-icons/ci";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { login } from "../redux/slices/authSlice";
import API from "../utils/axiosInstance";
import ForgotPassword from "../Components/Modals/ForgotPassword";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [forgotScreen, setForgotScreen] = useState(false);
  const [isAuthScreen, setIsAuthScreen] = useState(false);
  const [userId, setUserId] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [token, setToken] = useState("");

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.post("/auth/login", data).then((res) => {
      if (res.data.twofaEnabled) {
        setIsAuthScreen(true);
        setUserId(res.data.userId);
        return;
      }
      dispatch(login(res.data));
      navigate("/");
    });
  };
  const submitToken = (e) => {
    e.preventDefault();
    API.post("/auth/2fa/verifyOTP", { user: userId, token }).then((res) => {
      dispatch(login({ user: res.data.user, tokens: res.data.tokens }));
      navigate("/");
    });
  };

  return (
    <div className="container-fluid signup_container py-5 d-flex justify-content-center align-items-center">
      {forgotScreen && (
        <ForgotPassword
          show={forgotScreen}
          onHide={() => setForgotScreen(false)}
        />
      )}
      <div className="signup-box d-flex">
        {!isAuthScreen ? (
          <>
            <div className="s_welcome w-50 py-4 bg-primary text-white d-flex flex-column align-items-center text-center">
              <div className="s_logo">
                <img src={logo} alt="" />
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column gap-2 h-100">
                <h2 className="fw-bold">Hello</h2>
                <div className="underline bg-white"></div>
                <p style={{ fontSize: "12px" }}>
                  Enter your personal details
                  <br />
                  and start journey with us.
                </p>
              </div>
            </div>
            <div className="signup_form py-4">
              <div className="w-75">
                <h5 className="fw-bold text-center">Sign in to Backyard</h5>
                <div className="underline bg-primary mb-3 mt-3"></div>
                <form onSubmit={handleSubmit}>
                  <div className="d-none search-box s_input d-md-flex align-items-center">
                    <SlEnvolope size={16} className="icon" />
                    <input
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter your Email"
                    />
                  </div>
                  <div className="mt-3 search-box s_input d-md-flex align-items-center">
                    <CiLock size={18} className="icon" />
                    <input
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter your Password"
                    />
                    {passwordShown ? (
                      <AiOutlineEye
                        size={18}
                        className="icon cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        size={18}
                        className="icon cursor-pointer"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="checkbox d-flex align-items-center my-4">
                      <input type="checkbox" id="remember" />
                      <label htmlFor="remember">Remember me</label>
                    </div>
                    <p
                      className="forgot m-0 cursor-pointer"
                      onClick={() => setForgotScreen(true)}
                    >
                      Forgot your password?
                    </p>
                  </div>
                  <button className="btn bg-primary text-white w-100 fw-semibold">
                    Login
                  </button>
                </form>
                {/* <div className="d-flex align-items-center justify-content-center gap-2 mx-auto my-4">
                  <div className="continue_line"></div>
                  <span className="continue">or continue with</span>
                  <div className="continue_line"></div>
                </div> */}
                <div className="checkbox d-flex mt-4">
                  <input
                    id="terms-agreement"
                    type="checkbox"
                    className="mb-3"
                  />
                  <label htmlFor="terms-agreement">
                    By continuing you indicate that you agree to Backyard's
                    <b>Terms of Service and Privacy-policy.</b>
                  </label>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center gap-3 my-2">
                  <div className="d-flex justify-content-center search-box s_input d-md-flex align-items-center cursor-pointer">
                    <FcGoogle size={22} className="icon" />
                    <b style={{ color: "#747474" }}>Google</b>
                  </div>
                  <div className="d-flex justify-content-center search-box s_input d-md-flex align-items-center cursor-pointer">
                    <FaFacebook color="#1877F2" size={22} className="icon" />
                    <b style={{ color: "#747474" }}>Facebook</b>
                  </div>
                </div> */}
                <p className="continue text-center mt-4">
                  Don't have an Account?{" "}
                  <Link
                    className="fw-semibold"
                    to="/signup"
                    style={{ color: "#5ae6cb" }}
                  >
                    Sign Up With Email
                  </Link>
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="signup_form border-all w-50 m-auto py-4 px-2">
            <div className="w-100">
              <h5 className="fw-bold text-center">Enter authenticator code</h5>
              <div className="underline bg-primary mb-3 mt-3"></div>
              <form onSubmit={submitToken}>
                <div className="d-none search-box s_input d-md-flex align-items-center">
                  <input
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                    type="number"
                    placeholder="Enter Authenticator Code"
                  />
                </div>

                <button className="btn bg-primary text-white w-100 fw-semibold mt-3">
                  Login
                </button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signin;
