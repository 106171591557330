import React, { useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { GoDeviceMobile } from "react-icons/go";
import { FaPaypal, FaFacebook } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import ads from "../../assets/google-ads.png";
import paypallImg from "../../assets/paypall.png";
import microsoft from "../../assets/microsoft.png";
import { FaLinkedin } from "react-icons/fa";

const ConnectedApps = () => {
  const [paypall, setPaypall] = useState(false);
  const [facebook, setFacebook] = useState(false);
  const [google, setGoogle] = useState(false);
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading">Connected Apps</h3>
        <div className="my-4">
          <div className="mb-2">
            <div className="d-flex align-items-center gap-2 ms-2">
              <GoDeviceMobile size={18} className="icon " />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Apps
              </label>
            </div>
          </div>
          <div className="mb-4">
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <div className="d-flex align-items-center gap-2  w-100 ">
                {/* <FaPaypal size={18} className="icon " /> */}
                <img src={paypallImg} alt="paypall" className="paypall" />
                <label className="cursor-pointer ">Paypall</label>
              </div>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={paypall}
                    onChange={() => setPaypall(!paypall)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <div className="d-flex align-items-center gap-2  w-100 ">
                <FaFacebook color="#1877F2" size={22} className="icon" />
                <label className="cursor-pointer  w-100 ">Facebook </label>
              </div>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={facebook}
                    onChange={() => setFacebook(!facebook)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
          <div>
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <div className="d-flex align-items-center gap-2  w-100 ">
                <FcGoogle size={22} className="icon" />
                <label className="cursor-pointer  w-100 ">Google </label>
              </div>{" "}
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={google}
                    onChange={() => setGoogle(!google)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="primary-heading"> App Permissions</h3>
          <div className="my-4">
            <div className="mb-4">
              <div className="profile-edit w-100 py-3">
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-3">
                    <img className="img_icon" src={ads} alt="" />
                    <span className="fw-semibold">Google Ads</span>
                  </div>
                  <button className="btn btn-outline-danger fw-semibold">
                    Unauthorize
                  </button>
                </div>
                <span className="small-text">Permissions</span>
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="google1"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="google1"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Access your email address
                  </label>
                </div>{" "}
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="google2"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="google2"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Know what servers you're in
                  </label>
                </div>
              </div>
            </div>{" "}
            <div className="mb-4">
              <div className="profile-edit w-100 py-3">
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-3">
                    <FaLinkedin
                      size={30}
                      color="#0077B5"
                      className=" cursor-pointer"
                    />{" "}
                    <span className="fw-semibold">LinkedIn</span>
                  </div>
                  <button className="btn btn-outline-danger fw-semibold">
                    Unauthorize
                  </button>
                </div>
                <span className="small-text">Permissions</span>
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="LinkedIn1"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="LinkedIn1"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Access your email address
                  </label>
                </div>{" "}
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="LinkedIn2"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="LinkedIn2"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Know what servers you're in
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="profile-edit w-100 py-3">
                <div className="d-flex justify-content-between mb-2">
                  <div className="d-flex align-items-center gap-3">
                    <img className="img_icon" src={microsoft} alt="" />
                    <span className="fw-semibold">Microsoft</span>
                  </div>
                  <button className="btn btn-outline-danger fw-semibold">
                    Unauthorize
                  </button>
                </div>
                <span className="small-text">Permissions</span>
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="microsoft1"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="microsoft1"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Access your email address
                  </label>
                </div>{" "}
                <div className="mt-2 d-flex gap-1 align-items-center">
                  <input
                    type="radio"
                    name="radio"
                    id="microsoft2"
                    className="cursor-pointer"
                  />
                  <label
                    htmlFor="microsoft2"
                    className="fw-semibold cursor-pointer"
                  >
                    {" "}
                    Know what servers you're in
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default ConnectedApps;
