import { useEffect, useRef, useState } from "react";
import SearchedUser from "./SearchedUser";
import SearchedPost from "./SearchedPost";
import { CSSTransition } from "react-transition-group";
import { IoIosSearch } from "react-icons/io";
import API from "../utils/axiosInstance";

const SearchResults = () => {
  const resultsRef = useRef();
  const resultsDivRef = useRef();
  const [showResults, setShowResults] = useState(false);
  const [activeTab, setActiveTab] = useState("users");
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(search);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);

  // Debounce the search term
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedTerm(search);
    }, 500); // Adjust delay time (in ms) as needed

    // Cleanup the timer if user continues typing
    return () => clearTimeout(timerId);
  }, [search]);

  useEffect(() => {
    if (debouncedTerm) {
      fetchPosts(debouncedTerm);
    }
  }, [debouncedTerm, activeTab]);

  useEffect(() => {
    // Add event listener
    document.addEventListener("mousedown", (e) => {
      if (resultsDivRef.current && !resultsDivRef.current.contains(e.target)) {
        setShowResults(false);
      }
    });

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", (e) => {
        if (
          resultsDivRef.current &&
          !resultsDivRef.current.contains(e.target)
        ) {
          setShowResults(false);
        }
      });
    };
  }, []);

  const fetchPosts = async (searchTerm) => {
    if (activeTab === "users") {
      API.post("/user/search", { keyword: searchTerm }).then((res) => {
        setUsers(res.data);
      });
    } else {
      API.post("/post/search", { keyword: searchTerm }).then((res) => {
        setPosts(res.data);
      });
    }
  };

  return (
    <>
      <div
        className="d-none search-box d-md-flex align-items-center position-relative"
        ref={resultsDivRef}
      >
        <IoIosSearch size={22} className="icon" />
        <input
          type="text"
          placeholder="Search"
          onFocus={() => setShowResults(true)}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <CSSTransition
          in={showResults}
          timeout={300}
          nodeRef={resultsRef}
          classNames="profile-menu search-results"
          unmountOnExit
        >
          <div ref={resultsRef} className="profile-menu search-results p-0">
            <div className="position-sticky top-0 bg-white">
              <div className="d-flex align-items-center justify-content-between border-bottom-gray position-relative px-5 pb-2">
                <p
                  className={`fw-bold m-0 ${
                    activeTab === "users" ? "text-dark" : "text_muted"
                  }`}
                  onClick={() => setActiveTab("users")}
                >
                  Users
                </p>
                <p
                  className={`fw-bold m-0 ${
                    activeTab === "posts" ? "text-dark" : "text_muted"
                  }`}
                  onClick={() => setActiveTab("posts")}
                >
                  Posts
                </p>

                <div
                  className="position-absolute"
                  style={{
                    bottom: "0",
                    height: "3px",
                    borderRadius: "2px",
                    backgroundColor: "#5ae6cb",
                    width: activeTab === "users" ? "15%" : "15%",
                    left: activeTab === "users" ? "12%" : "73%",
                    transition: "left 0.3s ease",
                  }}
                ></div>
              </div>
            </div>

            {activeTab === "users" ? (
              <div className="mt-2">
                {users.map((user) => (
                  <SearchedUser user={user} key={user.id} />
                ))}
              </div>
            ) : (
              <div className="mt-2">
                {posts.map((post, i) => (
                  <SearchedPost post={post} key={i} />
                ))}
              </div>
            )}
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

export default SearchResults;
