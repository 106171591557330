import { Link } from "react-router-dom";
import logo from "../assets/logo-white.png";

const Footer = () => {
  return (
    <footer className="px-2 pt-5 pb-2">
      <div className="container">
        <div className="d-flex py-4 align-items-center justify-content-between flex-column flex-sm-row gap-2 gap-sm-0">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <div className="d-flex gap-4">
            <Link className="footer-link">Terms of service</Link>
            <Link className="footer-link">Privacy & Policy</Link>
          </div>
        </div>
        <hr className="horizontal-divider" />
        <p className="footer-link text-center">
          &copy; Copyright Backyard 2024, All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
