import React, { useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { FiUser } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiUserForbidLine } from "react-icons/ri";

const Privacy = () => {
  const [activity, setActivity] = useState(false);
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Privacy & Security</h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <FiUser />
              <label class="form-label small-text ">
                {" "}
                Who can see my profile
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 "> Select Users</label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <RiUserForbidLine />
              <label class="form-label small-text "> View Blocked Users</label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 "> View Users</label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>{" "}
          <div>
            <div className=" w-100 d-md-flex align-items-center mt-1 ">
              <label className="cursor-pointer  w-100 ">Activity Status</label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={activity}
                    onChange={() => setActivity(!activity)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default Privacy;
