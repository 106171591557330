import React from "react";
import { Link } from "react-router-dom";

const SearchedPost = ({ post }) => {
  return (
    <Link
      to={`/topic/${post._id}`}
      className="d-flex justify-content-between px-2 searched-post my-2"
    >
      <div>
        <div className="d-flex align-items-center gap-2">
          <img
            src={post.userDetails.profile}
            alt=""
            className="searched-user-img"
          />
          <p className="searched-user-name fw-bold m-0">
            {post.userDetails.firstName} {post.userDetails.lastName}
          </p>
        </div>
        <p className="searched-post-text">{post.caption}</p>
      </div>
      {post.image && (
        <img src={post.image} alt="" className="searched-post-img mb-2" />
      )}
    </Link>
  );
};

export default SearchedPost;
