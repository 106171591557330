import React, { useRef, useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { GoPencil } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { updateData, updateProfile } from "../../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import API from "../../utils/axiosInstance";

const ProfileSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const [data, setData] = useState({
    firstName: user?.firstName,
    lastName: user?.lastName,
    bio: user?.bio,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    API.post("/user/updateProfile", formData).then((res) => {
      dispatch(updateProfile(res.data?.profile));
    });
    e.target.files = [];
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    API.patch("/user/updateProfile", data).then((res) => {
      dispatch(updateData(data));
    });
  };

  return (
    <SettingLayout>
      <div className=" profile-settings">
        <h3 className="primary-heading">Profile Settings</h3>
        <div className="d-flex align-items-center gap-3 mt-4">
          <div className="profile-img">
            <img src={user?.profile} alt="profile" />
          </div>
          <div className="">
            <h2>{user?.username}</h2>
            <div className="d-flex gap-3 mt-2">
              <div>
                <input
                  onChange={handleImageUpload}
                  type="file"
                  id="file-upload"
                  className="d-none"
                  accept="image/*"
                />
                <label
                  htmlFor="file-upload"
                  className=" fw-semibold btn2 cursor-pointer px-3"
                >
                  + Upload New Photo
                </label>
              </div>

              {/* <button className="cancel px-3">Cancel Upload</button> */}
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} className="my-4">
          <div class="mb-4 w-100 ">
            <div className="d-flex align-items-center gap-2 ms-2">
              <GoPencil />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Edit Name
              </label>
            </div>
            <div className="d-flex">
              <input
                type="text"
                className="w-100 mt-1 profile-edit"
                placeholder="First Name..."
                name="firstName"
                value={data.firstName}
                onChange={handleChange}
              />
              <input
                type="text"
                className="ms-2 w-100 mt-1 profile-edit"
                placeholder="Last Name..."
                name="lastName"
                value={data.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center gap-2 ms-2">
              <GoPencil />

              <label
                for="exampleFormControlTextarea1"
                class="form-label small-text "
              >
                Update Bio
              </label>
            </div>

            <textarea
              placeholder="Edit Bio..."
              className=" w-100 mt-1 profile-edit"
              id="exampleFormControlTextarea1"
              name="bio"
              value={data.bio}
              onChange={handleChange}
              rows="3"
            ></textarea>
          </div>
          <button className="fw-semibold btn2 cursor-pointer px-4 mt-3">
            Update
          </button>
        </form>
      </div>
    </SettingLayout>
  );
};

export default ProfileSetting;
