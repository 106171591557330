import { createSlice } from "@reduxjs/toolkit";

export const authState = {
  isAuthenticated: false,
  tokens: {
    access: {
      token: "",
      expires: "",
    },
    refresh: {
      token: "",
      expires: "",
    },
  },
  user: {
    id: "",
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    profile: "",
    bio: "",
    twoFactorEnabled: false,
    notifications: {
      notificationPreferences: "all",
      email: false,
      sms: false,
      push: false,
    },
    contentPreferences: {
      contentFilter: "no",
      ad: "personalized",
      contentDisplay: "list",
    },
  },
};

export const authSlice = createSlice({
  name: "authData",
  initialState: authState,
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.tokens = action.payload.tokens;
      state.user = action.payload.user;
    },
    logout: () => authState,
    updateProfile: (state, action) => {
      state.user.profile = action.payload;
    },
    updateData: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

export const { login, logout, updateData, updateProfile } = authSlice.actions;

export default authSlice.reducer;
