import React, { useEffect, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { CiLock } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import API from "../utils/axiosInstance";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    if (!token) {
      return navigate("/");
    }
    API.post("/auth/verify-forgot-token", { token }).catch(() => {
      navigate("/");
    });
  }, [token]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password !== data.confirmPassword) {
      return toast.error("Passwords do not match");
    }
    API.post("/auth/reset-password", { token, password: data.password }).then(
      (res) => {
        navigate("/login");
      }
    );
  };

  return (
    <Layout>
      <div className="d-flex justify-content-center my-5">
        <div className="signup_form py-4 w-50 border-all">
          <div className="w-75">
            <h5 className="fw-bold text-center">Reset Password</h5>
            <div className="underline bg-primary mb-3 mt-3"></div>
            <form onSubmit={handleSubmit}>
              <div className="my-3 search-box s_input d-md-flex align-items-center">
                <CiLock size={18} className="icon" />
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your Password"
                  value={data.password}
                  name="password"
                  onChange={handleChange}
                />
                {passwordShown ? (
                  <AiOutlineEye
                    size={18}
                    className="icon cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={18}
                    className="icon cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              <div className="search-box s_input d-md-flex align-items-center">
                <CiLock size={18} className="icon" />
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={data.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                />
                {confirmPasswordShown ? (
                  <AiOutlineEye
                    size={18}
                    className="icon cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={18}
                    className="icon cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>

              <button className="btn bg-primary text-white w-100 fw-semibold mt-3">
                Reset
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
