import React, { useState } from "react";
import logo from "../assets/fullWhite-logo.png";
import { TbUserEdit } from "react-icons/tb";
import { SlEnvolope } from "react-icons/sl";
import { CiLock } from "react-icons/ci";
import { Link } from "react-router-dom";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { toast } from "react-toastify";
import API from "../utils/axiosInstance";
import VerifyEmail from "../Components/Modals/VerifyEmail";

const Signup = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [verifyScreen, setVerifyScreen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const sendOTP = (e) => {
    e.preventDefault();
    const { email, username } = data;
    if (data.password !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    API.post("/auth/sendVerifyOtp", { email, username }).then((res) => {
      setVerifyScreen(true);
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <div className="container-fluid signup_container py-5 d-flex justify-content-center align-items-center">
      {verifyScreen && (
        <VerifyEmail
          show={verifyScreen}
          onHide={() => setVerifyScreen(false)}
          data={data}
        />
      )}
      <div className="signup-box d-flex">
        <div className="s_welcome w-50 py-4 bg-primary text-white d-flex flex-column align-items-center text-center">
          <div className="s_logo">
            <img src={logo} alt="" />
          </div>
          <div className="d-flex justify-content-center align-items-center flex-column gap-2 h-100">
            <h2 className="fw-bold">Welcome!</h2>
            <div className="underline bg-white"></div>
            <p style={{ fontSize: "12px" }}>
              Join us! Connect, share, and
              <br />
              grow together.
            </p>
          </div>
        </div>
        <div className="signup_form py-4">
          <div className="w-75">
            <h5 className="fw-bold text-center">Sign Up to Backyard</h5>
            <div className="underline bg-primary mb-3 mt-3"></div>
            <form onSubmit={sendOTP}>
              <div className="d-none search-box s_input d-md-flex align-items-center">
                <TbUserEdit size={18} className="icon" />
                <input
                  type="text"
                  placeholder="First Name"
                  value={data.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
              </div>
              <div className="my-3 d-none search-box s_input d-md-flex align-items-center">
                <TbUserEdit size={18} className="icon" />
                <input
                  type="text"
                  placeholder="Last Name"
                  value={data.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
              </div>
              <div className="my-3 d-none search-box s_input d-md-flex align-items-center">
                <TbUserEdit size={18} className="icon" />
                <input
                  type="text"
                  placeholder="Username"
                  value={data.username}
                  name="username"
                  onChange={handleChange}
                />
              </div>
              <div className="d-none search-box s_input d-md-flex align-items-center">
                <SlEnvolope size={16} className="icon" />
                <input
                  type="text"
                  placeholder="Enter your Email"
                  value={data.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="my-3 search-box s_input d-md-flex align-items-center">
                <CiLock size={18} className="icon" />
                <input
                  type={passwordShown ? "text" : "password"}
                  placeholder="Enter your Password"
                  value={data.password}
                  name="password"
                  onChange={handleChange}
                />
                {passwordShown ? (
                  <AiOutlineEye
                    size={18}
                    className="icon cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={18}
                    className="icon cursor-pointer"
                    onClick={togglePasswordVisibility}
                  />
                )}
              </div>
              <div className="search-box s_input d-md-flex align-items-center">
                <CiLock size={18} className="icon" />
                <input
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={data.confirmPassword}
                  name="confirmPassword"
                  onChange={handleChange}
                />
                {confirmPasswordShown ? (
                  <AiOutlineEye
                    size={18}
                    className="icon cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    size={18}
                    className="icon cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  />
                )}
              </div>
              <div className="checkbox d-flex align-items-center my-4">
                <input type="checkbox" id="remember" />
                <label htmlFor="remember">Remember me</label>
              </div>
              <button className="btn bg-primary text-white w-100 fw-semibold">
                Sign Up
              </button>
            </form>
            {/* <div className="d-flex align-items-center justify-content-center gap-2 mx-auto my-4">
              <div className="continue_line"></div>
              <span className="continue">or continue with</span>
              <div className="continue_line"></div>
            </div> */}
            <div className="checkbox d-flex mt-4">
              <input type="checkbox" id="privacy-agreement" className="mb-3" />
              <label htmlFor="privacy-agreement">
                By continuing you indicate that you agree to Backyard's
                <b>Terms of Service and Privacy-policy.</b>
              </label>
            </div>
            {/* <div className="d-flex align-items-center justify-content-center gap-3 my-2">
              <div className="d-flex justify-content-center search-box s_input d-md-flex align-items-center cursor-pointer">
                <FcGoogle size={22} className="icon" />
                <b style={{ color: "#747474" }}>Google</b>
              </div>
              <div className="d-flex justify-content-center search-box s_input d-md-flex align-items-center cursor-pointer">
                <FaFacebook color="#1877F2" size={22} className="icon" />
                <b style={{ color: "#747474" }}>Facebook</b>
              </div>
            </div> */}
            <p className="continue text-center mt-4">
              Already have an account?{" "}
              <Link
                className="fw-semibold"
                to="/login"
                style={{ color: "#5ae6cb" }}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
