import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IoIosSend } from "react-icons/io";
import API from "../utils/axiosInstance";

const Comment = ({ comment, comments, setPost, postId }) => {
  const inputRef = useRef();
  const [showReply, setShowReply] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [subComments, setSubComments] = useState([]);

  useEffect(() => {
    filterComments();
  }, [comment, comments]);

  const filterComments = () => {
    const subCmts = comments.filter((cmt) => cmt.repliedTo == comment.id);
    setSubComments(subCmts);
  };

  const addComment = (e) => {
    e.preventDefault();
    API.patch(`/post/s/${postId}`, {
      comment: commentValue,
      repliedTo: comment.id,
    }).then((res) => {
      setPost((prev) => {
        return { ...prev, comments: [...prev.comments, res.data.result] };
      });
      // filterComments();
      setCommentValue("");
    });
  };

  return (
    <div className="comment-box d-flex gap-2">
      <div className="comment-img">
        <img src={comment?.user?.profile} alt="" />
      </div>
      <div className="comment-info">
        <div className="comment">
          <p className="primary-text">
            {comment?.user?.firstName} {comment?.user?.lastName}
          </p>
          <p className="small-text font-weight-normal">{comment?.comment}</p>
        </div>
        <div className="d-flex gap-3 mt-1">
          <p className="small-text lighter-font">
            {moment(comment?.createdAt).fromNow()}
          </p>
          <p className="small-text cursor-pointer">Like</p>
          <p
            onClick={() => {
              setShowReply(!showReply);
              inputRef.current.focus();
            }}
            className="small-text cursor-pointer"
          >
            Reply
          </p>
        </div>
        {subComments.length > 0 &&
          subComments.map((comment) => (
            <Comment
              comment={comment}
              comments={comments}
              setPost={setPost}
              postId={postId}
            />
          ))}
        {showReply && (
          <form
            onSubmit={addComment}
            className="mt-2 d-flex align-items-center gap-2 border-1 px-2 rounded-1"
            style={{ border: "1px solid black" }}
          >
            <input
              type="text"
              ref={inputRef}
              className="border-0 w-100 py-1"
              placeholder="Write a comment"
              style={{ outline: "none", fontSize: "14px" }}
              value={commentValue}
              onChange={(e) => setCommentValue(e.target.value)}
            />
            <button
              type="submit"
              className="cursor-pointer bg-transparent border-0"
            >
              <IoIosSend size={20} />
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default Comment;
