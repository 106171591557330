import API from "../utils/axiosInstance";

const followUserService = (user) => {
  return API.post("/user/followers", { user })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export { followUserService };
