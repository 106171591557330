import { useEffect } from "react";
import Routes from "./Routes/Routes";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { socket } from "./utils/socket";

const App = () => {
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  useEffect(() => {
    if (isAuthenticated && socket) {
      socket.emit("login", user.id);
    }
  }, [isAuthenticated, socket]);

  return (
    <div>
      <ToastContainer
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes />
    </div>
  );
};

export default App;
