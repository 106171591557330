import React from "react";
import SettingLayout from "../../Components/SettingLayout";
import { FiUser } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import Dropdown from "../../Components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../redux/slices/authSlice";
import API from "../../utils/axiosInstance";

const CONTENT_FILTERS = [
  {
    label: "No filter (view all content)",
    value: "no",
  },
  {
    label: "Family-friendly content only",
    value: "family",
  },
  {
    label: "Hide explicit content",
    value: "explicit",
  },
  { label: "Custom filter", value: "custom" },
];

const AD_PREFERENCES = [
  {
    label: "Personalized ads based on activity",
    value: "personalized",
  },
  {
    label: "Non-personalized ads",
    value: "non-personalized",
  },
  {
    label: "Minimal ads",
    value: "minimal",
  },
  { label: "No ads", value: "no" },
];

const CONTENT_DISPLAY = [
  { label: "Grid View", value: "grid" },
  { label: "List View", value: "list" },
  { label: "Compact Mode", value: "compact" },
];

const ContentPreferences = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const updateContentFilters = (value) => {
    API.post("/user/updateContentPreferences", {
      contentFilter: value,
    }).then((res) => {
      dispatch(
        updateData({
          contentPreferences: {
            ...user.contentPreferences,
            contentFilter: value,
          },
        })
      );
    });
  };

  const updateAdPreferences = (value) => {
    API.post("/user/updateContentPreferences", {
      ad: value,
    }).then((res) => {
      dispatch(
        updateData({
          contentPreferences: {
            ...user.contentPreferences,
            ad: value,
          },
        })
      );
    });
  };

  const updateDisplay = (value) => {
    API.post("/user/updateContentPreferences", {
      contentDisplay: value,
    }).then((res) => {
      dispatch(
        updateData({
          contentPreferences: {
            ...user.contentPreferences,
            contentDisplay: value,
          },
        })
      );
    });
  };
  const filterContent = () => {
    const data = CONTENT_FILTERS.find(
      (p) => p.value === user.contentPreferences.contentFilter
    );
    return data.label;
  };

  const filterAd = () => {
    const data = AD_PREFERENCES.find(
      (p) => p.value === user.contentPreferences.ad
    );
    return data.label;
  };

  const filterDisplay = () => {
    const data = CONTENT_DISPLAY.find(
      (p) => p.value === user.contentPreferences.contentDisplay
    );
    return data.label;
  };

  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading">Content Preference</h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <FiUser />
              <label class="form-label small-text ">Content Filters</label>
            </div>

            <Dropdown
              items={CONTENT_FILTERS}
              onChange={updateContentFilters}
              value={filterContent()}
            />
          </div>{" "}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <FiUser />
              <label class="form-label small-text ">Ad Preferences</label>
            </div>
            <Dropdown
              items={AD_PREFERENCES}
              onChange={updateAdPreferences}
              value={filterAd()}
            />
          </div>{" "}
          <div>
            <div className="d-flex align-items-center gap-2 ms-2">
              <FiUser />
              <label class="form-label small-text ">
                Content Display Options
              </label>
            </div>
            <Dropdown
              items={CONTENT_DISPLAY}
              onChange={updateDisplay}
              value={filterDisplay()}
            />
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default ContentPreferences;
