import React, { useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { IoMoonOutline } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaFont } from "react-icons/fa";
import { CiLaptop } from "react-icons/ci";

const Appearance = () => {
  const [appearances, setAppearances] = useState(false);
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Appearance Settings</h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <IoMoonOutline />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Appearance
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label htmlFor="appearance" className=" cursor-pointer w-100 ">
                Dark Mode
              </label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    id="appearance"
                    type="checkbox"
                    checked={appearances}
                    onChange={() => setAppearances(!appearances)}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <FaFont />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Font Size
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label htmlFor="" className="cursor-pointer  w-100 ">
                Select Font Size
              </label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>{" "}
          <div>
            <div className="d-flex align-items-center gap-2 ms-2">
              <CiLaptop />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Display Preference
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label htmlFor="" className="cursor-pointer  w-100 ">
                Select Your Display Size
              </label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default Appearance;
