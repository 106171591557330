import Comment from "./Comment";
import { PiShareFatBold } from "react-icons/pi";
import { IoIosSend, IoMdArrowDropdown } from "react-icons/io";
import {
  TbDownload,
  TbMessageCircle,
  TbThumbDown,
  TbThumbUp,
} from "react-icons/tb";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { followUserService } from "../services/userService";
import API from "../utils/axiosInstance";

const Post = ({ post: defaultPost }) => {
  const { user } = useSelector((state) => state.auth);

  const nodeRef = useRef();
  const scrollRef = useRef();
  const [comment, setComment] = useState("");
  const [post, setPost] = useState(defaultPost);
  const [liked, setLiked] = useState(defaultPost?.isLiked);
  const [showComments, setShowComments] = useState(false);
  const [isFollowing, setFollowing] = useState(defaultPost?.isFollowing);

  useEffect(() => {
    setPost(defaultPost);
    setLiked(defaultPost?.isLiked);
    setFollowing(defaultPost?.isFollowing);
  }, [defaultPost]);

  const handleLike = () => {
    API.put(`/post/s/${post?.id}`).then((res) => {
      setLiked(!liked);
      if (liked) {
        setPost({ ...post, likesCount: post?.likesCount - 1 });
      } else {
        setPost({ ...post, likesCount: post?.likesCount + 1 });
      }
    });
  };

  const addComment = (e) => {
    e.preventDefault();
    API.patch(`/post/s/${post?.id}`, { comment }).then((res) => {
      setPost({ ...post, comments: [...post.comments, res.data.result] });
      setComment("");
    });
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollRef]);

  const handleFollowUser = () => {
    followUserService(post?.user?.id).then((res) => {
      setFollowing(!isFollowing);
    });
  };

  return (
    <div className="mt-4">
      <div className="d-flex align-items-center gap-3 pe-2">
        <div className="d-flex topic-profile align-items-center gap-2">
          <img className="" src={post?.user?.profile} alt="" />
          <p className="small-text">
            {post?.user?.firstName} {post?.user?.lastName}
          </p>
        </div>
        <div className="divider"></div>
        <p className="small-text">
          {moment(post?.createdAt).format("DD MMM, YYYY")}
        </p>
        {user?.id != post?.user?.id && (
          <button onClick={handleFollowUser} className="btn5 ms-2">
            {isFollowing ? "Following" : "Follow"}
          </button>
        )}
      </div>
      <Link to={`/topic/${post?.id}`} className="cursor-pointer">
        <h3 className="primary-heading my-2">{post?.caption}</h3>
        {post?.image && (
          <div className="post-pic">
            <img src={post?.image} alt="" />
          </div>
        )}
      </Link>
      <p className="primary-text font-weight-bold my-3">{post?.hashtags}</p>
      <div className="post-actions d-flex justify-content-between align-items-center py-2">
        <div className="d-flex align-items-center gap-3">
          <p
            onClick={handleLike}
            className={`small-text d-flex align-items-center gap-1 cursor-pointer primary-hover ${
              liked && "primary-color"
            }`}
          >
            <TbThumbUp size={18} /> {post?.likesCount}
          </p>
          <p className="small-text d-flex align-items-center gap-2 cursor-pointer primary-hover">
            <TbThumbDown size={18} />
          </p>

          <p
            onClick={() => {
              setShowComments(!showComments);
            }}
            className="small-text d-flex align-items-center gap-1 cursor-pointer primary-hover"
          >
            <TbMessageCircle size={18} /> {post?.comments?.length}
          </p>
        </div>
        <div className="d-flex align-items-center gap-3">
          <p className="small-text cursor-pointer primary-hover">
            <PiShareFatBold size={18} />
          </p>
          <p className="small-text cursor-pointer primary-hover">
            <TbDownload size={18} />
          </p>
        </div>
      </div>
      <CSSTransition
        in={showComments}
        timeout={300}
        nodeRef={nodeRef}
        classNames="comments-section"
        unmountOnExit
      >
        {/* {showComments ? ( */}
        <div ref={nodeRef} className="comment-section">
          {post?.comments?.length > 0 ? (
            <>
              <div className="d-flex my-3 align-items-center gap-1">
                <p className="small-text cursor-pointer">Most relevant</p>
                <IoMdArrowDropdown size={18} />
              </div>
              <div className="comments">
                {post?.comments
                  ?.filter((comment) => !comment.repliedTo)
                  ?.map((comment, i) => (
                    <Comment
                      key={i}
                      comment={comment}
                      comments={post?.comments}
                      setPost={setPost}
                      postId={post?.id}
                      ref={
                        i ===
                        post?.comments?.filter((comment) => !comment.repliedTo)
                          .length -
                          1
                          ? scrollRef
                          : null
                      }
                    />
                  ))}
              </div>
            </>
          ) : (
            <h5 className="text-center mt-2">No comments</h5>
          )}
          <form
            onSubmit={addComment}
            className="mt-2 d-flex align-items-center gap-2 border-1 px-2 rounded-1"
            style={{ border: "1px solid black" }}
          >
            <input
              type="text"
              className="border-0 w-100 py-2"
              placeholder="Write a comment"
              style={{ outline: "none" }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button
              type="submit"
              className="cursor-pointer bg-transparent border-0"
            >
              <IoIosSend size={22} />
            </button>
          </form>
        </div>
        {/* ) : (
          <div></div>
        )} */}
      </CSSTransition>
    </div>
  );
};

export default Post;
