import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import UserInfo from "../UserInfo";
import API from "../../utils/axiosInstance";

const UsersList = ({ show, onHide }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    API.get("/user/following").then((res) => {
      setUsers(res.data);
    });
  }, []);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="">
          {users?.map((user) => (
            <UserInfo user={user} onHide={onHide} />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn1" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UsersList;
