import React from "react";
import { MdLanguage } from "react-icons/md";
import { IoMdTime } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdKeyboardArrowDown } from "react-icons/md";
import SettingLayout from "../../Components/SettingLayout";

const Language = () => {
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Language and Region</h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <MdLanguage className="icon" />
              <label class="form-label small-text ">Language</label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                {" "}
                Select Your Language
              </label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <IoMdTime className="icon" />
              <label class="form-label small-text "> Time Zone</label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                {" "}
                Select Your Time Zone
              </label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>{" "}
          <div>
            <div className="d-flex align-items-center gap-2 ms-2">
              <HiOutlineLocationMarker className="icon" />
              <label class="form-label small-text ">
                Regional Contact Settings
              </label>
            </div>

            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                Select Your Region
              </label>
              <MdKeyboardArrowDown size={18} className="icon cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default Language;
