// import React from "react";
// import { IoSettingsOutline } from "react-icons/io5";
// import { TbUserEdit } from "react-icons/tb";
// import { LuBellDot } from "react-icons/lu";
// import { FaRocketchat } from "react-icons/fa";
// import { IoMoonOutline } from "react-icons/io5";
// import { BsBagHeart } from "react-icons/bs";
// import { CgScreen } from "react-icons/cg";
// import { MdOutlinePrivacyTip } from "react-icons/md";
// import { HiOutlineGlobeEuropeAfrica } from "react-icons/hi2";
// import { GiOpenBook } from "react-icons/gi";
// import { IoIosHelpCircleOutline } from "react-icons/io";
// import { IoLogOutOutline } from "react-icons/io5";
// import { Link } from "react-router-dom";

// const SettingSidebar = () => {
//   return (
//     <div className="d-none col-3 sidebar d-sm-none d-md-block">
//       <div className="sidebar-container">
//         <div className="s_sidebar_box ">
//           <p className="primary-text mb-3">Account</p>
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <IoSettingsOutline size={18} className="icon" />
//             <Link to="/settings" className="small-text">
//               Profile Settings
//             </Link>
//           </div>
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <TbUserEdit size={18} className="icon" />
//             <Link to="/settings/account" className="small-text">
//               Account Settings
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <LuBellDot size={18} className="icon" />
//             <Link to="/settings/notification" className="small-text">
//               Notification Settings
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <FaRocketchat size={18} className="icon" />
//             <Link to="/settings/content-preferences" className="small-text">
//               Content Preferences
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <IoMoonOutline size={18} className="icon" />
//             <Link to="/settings/appearance" className="small-text">
//               Appearance Setting
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <BsBagHeart size={18} className="icon" />
//             <Link
//               to="/settings/subscription-and-billing"
//               className="small-text"
//             >
//               Subscription and Billing
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <CgScreen size={18} className="icon" />
//             <Link to="/settings/connected-apps" className="small-text">
//               Connected Apps
//             </Link>
//           </div>
//         </div>
//         <div className="s_sidebar_box ">
//           <p className="primary-text my-3">Privacy Preferences</p>
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <MdOutlinePrivacyTip size={18} className="icon" />
//             <Link to="/settings/privacy-and-security" className="small-text">
//               Privacy and Security
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <HiOutlineGlobeEuropeAfrica size={18} className="icon" />
//             <Link to="/settings/language-and-region" className="small-text">
//               Language and Region
//             </Link>
//           </div>
//         </div>{" "}
//         <div className="s_sidebar_box ">
//           <p className="primary-text my-3">Help Center</p>
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <GiOpenBook size={18} className="icon" />
//             <Link to="/settings/legal-and-policies" className="small-text">
//               Legal and Policies
//             </Link>
//           </div>{" "}
//           <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
//             <IoIosHelpCircleOutline size={18} className="icon" />
//             <Link to="/settings/help-and-support" className="small-text">Help and Support</Link>
//           </div>
//         </div>
//         <div>
//           <div className="d-flex gap-2 align-items-center my-4 cursor-pointer">
//             <IoLogOutOutline size={18} className="icon" />
//             <p className="small-text">Logout</p>
//           </div>{" "}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SettingSidebar;

import React from "react";
import {
  IoSettingsOutline,
  IoMoonOutline,
  IoLogOutOutline,
} from "react-icons/io5";
import { TbUserEdit } from "react-icons/tb";
import { LuBellDot } from "react-icons/lu";
import { FaRocketchat } from "react-icons/fa";
import { BsBagHeart } from "react-icons/bs";
import { CgScreen } from "react-icons/cg";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { HiOutlineGlobeEuropeAfrica } from "react-icons/hi2";
import { GiOpenBook } from "react-icons/gi";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import API from "../utils/axiosInstance";

const SettingSidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tokens } = useSelector((state) => state.auth);

  const links = [
    {
      to: "/settings",
      label: "Profile Settings",
      icon: <IoSettingsOutline size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/account",
      label: "Account Settings",
      icon: <TbUserEdit size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/notification",
      label: "Notification Settings",
      icon: <LuBellDot size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/content-preferences",
      label: "Content Preferences",
      icon: <FaRocketchat size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/appearance",
      label: "Appearance Setting",
      icon: <IoMoonOutline size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/subscription-and-billing",
      label: "Subscription and Billing",
      icon: <BsBagHeart size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/connected-apps",
      label: "Connected Apps",
      icon: <CgScreen size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/privacy-and-security",
      label: "Privacy and Security",
      icon: <MdOutlinePrivacyTip size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/language-and-region",
      label: "Language and Region",
      icon: <HiOutlineGlobeEuropeAfrica size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/legal-and-policies",
      label: "Legal and Policies",
      icon: <GiOpenBook size={18} className="sidebar_icon" />,
    },
    {
      to: "/settings/help-and-support",
      label: "Help and Support",
      icon: <IoIosHelpCircleOutline size={18} className="sidebar_icon" />,
    },
  ];

  const handleLogout = () => {
    API.post("/auth/logout", { refreshToken: tokens?.refresh?.token }).then(
      (res) => {
        dispatch(logout());
        navigate("/");
      }
    );
  };

  return (
    <div className="d-none col-3 sidebar d-sm-none d-md-block">
      <div className="sidebar-container">
        <div className="s_sidebar_box ">
          <p className="primary-text mb-3">Account</p>
          {links.slice(0, 7).map((link) => (
            <Link to={link.to}>
              <div
                key={link.to}
                className={`d-flex gap-2 align-items-center cursor-pointer tabs ${
                  location.pathname === link.to ? "active-link" : ""
                }`}
              >
                {link.icon}
                <p className="small-text">{link.label}</p>
              </div>
            </Link>
          ))}
        </div>
        <div className="s_sidebar_box ">
          <p className="primary-text my-3">Privacy Preferences</p>
          {links.slice(7, 9).map((link) => (
            <Link to={link.to}>
              <div
                key={link.to}
                className={`d-flex gap-2 align-items-center cursor-pointer tabs ${
                  location.pathname === link.to ? "active-link" : ""
                }`}
              >
                {link.icon}
                <p className="small-text"> {link.label}</p>
              </div>
            </Link>
          ))}
        </div>
        <div className="s_sidebar_box ">
          <p className="primary-text my-3">Help Center</p>
          {links.slice(9, 11).map((link) => (
            <Link to={link.to}>
              <div
                key={link.to}
                className={`d-flex gap-2 align-items-center cursor-pointer tabs ${
                  location.pathname === link.to ? "active-link" : ""
                }`}
              >
                {link.icon}
                <p className="small-text">{link.label}</p>
              </div>
            </Link>
          ))}
        </div>
        <div>
          <div
            onClick={handleLogout}
            className="d-flex gap-2 align-items-center my-4 cursor-pointer"
          >
            <IoLogOutOutline size={18} className="icon" />
            <p className="small-text">Logout</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingSidebar;
