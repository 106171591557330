import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../redux/slices/authSlice";
import API from "../../utils/axiosInstance";

const TwoFactorModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [otpModal, setOtpModal] = useState(false);
  const [otp, setOtp] = useState("");

  useEffect(() => {
    API.get("/auth/2fa/setup").then((res) => {
      setData(res.data);
    });
  }, []);

  const verifyOTP = () => {
    API.post("/auth/2fa/verify", {
      token: otp,
      user: user.id,
      secret: data.secret,
    }).then((res) => {
      if (res.data.success) {
        dispatch(updateData({ twoFactorEnabled: true }));
        setOtpModal(false);
        onHide();
      }
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Two Factor Authentication</Modal.Title>
      </Modal.Header>
      {data && !otpModal && (
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <p>
              Go to your <b>Authenticator App</b> and scan the QR code below
            </p>
            <img src={data.qrCode} className="qr-img" alt="" />

            <p>Or enter the following secret key</p>
            <p className="bg-primary text-white py-1 px-3 w-100 text-center rounded-1">
              {data?.secret}
            </p>
            <button onClick={() => setOtpModal(true)} className="btn1 w-100">
              Done
            </button>
          </div>
        </Modal.Body>
      )}
      {otpModal && (
        <Modal.Body>
          <div className="d-flex flex-column align-items-center">
            <p>
              Enter the code from your <b>Authenticator App</b>
            </p>

            <input
              type="text"
              className="profile-edit w-100 mb-3"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <button onClick={verifyOTP} className="btn1 w-100">
              Submit
            </button>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default TwoFactorModal;
