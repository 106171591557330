import { FaCamera } from "react-icons/fa";
import Layout from "../Components/Layout";
import { useEffect, useRef, useState } from "react";
import { FiPlus } from "react-icons/fi";
import Post from "../Components/Post";
import { TbCake, TbUserEdit } from "react-icons/tb";
import { VscSignOut } from "react-icons/vsc";
// import { cookies } from "../utils/constants";
import AddPost from "../Components/AddPost";
import { useDispatch, useSelector } from "react-redux";
import { logout, updateProfile } from "../redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import API from "../utils/axiosInstance";

const TABS = [
  "Overview",
  "Post",
  "Comments",
  "Saved",
  "Hidden",
  "Upvoted",
  "Downvoted",
];
const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, tokens } = useSelector((state) => state.auth);
  const profileInputRef = useRef();
  const [activeTab, setActiveTab] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState([]);

  const handleLogout = () => {
    API.post("/auth/logout", { refreshToken: tokens?.refresh?.token }).then(
      (res) => {
        dispatch(logout());
        navigate("/");
      }
    );
  };

  const handleEditProfile = () => {
    profileInputRef.current.click();
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    API.post("/user/updateProfile", formData).then((res) => {
      dispatch(updateProfile(res.data?.profile));
    });
    e.target.files = [];
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    API.get("/post").then((res) => {
      setPosts(res.data?.result);
    });
  };

  return (
    <Layout>
      <AddPost
        show={showModal}
        onHide={() => {
          setShowModal(false);
          fetchPosts();
        }}
      />
      <div className="container d-flex my-5 gap-4 flex-md-row flex-column-reverse">
        <div className="col-12 col-md-9">
          <div className="d-flex profile-info gap-4 align-items-center">
            <input
              type="file"
              className="d-none"
              ref={profileInputRef}
              accept="image/*"
              onChange={handleImageUpload}
            />
            <div
              className="profile-pic cursor-pointer"
              onClick={handleEditProfile}
            >
              <img src={user?.profile} alt="" />
              <div className="camera-icon">
                <FaCamera size={12} />
              </div>
            </div>
            <div className="profile-details">
              <h4 className="subheading m-0">
                {user?.firstName} {user?.lastName}
              </h4>
              <p className="small-text m-0">@{user?.username}</p>
            </div>
          </div>
          <div className="profile-tabs d-flex gap-3 mt-4">
            {TABS.map((tab, index) => (
              <button
                key={index}
                className={`tab-btn ${activeTab === index + 1 ? "active" : ""}`}
                onClick={() => setActiveTab(index + 1)}
              >
                {tab}
              </button>
            ))}
          </div>
          <button
            onClick={() => setShowModal(true)}
            className="btn3 mt-3 mb-4 d-flex align-items-center gap-2"
          >
            <FiPlus size={19} /> Create a post
          </button>
          <hr />
          <div className="posts mt-2">
            {posts?.map((post) => (
              <Post key={post.id} post={post} />
            ))}
          </div>
        </div>
        <div className="col-12 col-md-3">
          <div className="profile-card">
            <div
              className="profile-card-img cursor-pointer"
              onClick={handleEditProfile}
            >
              <img src={user?.profile} alt="" />
              <div className="camera-icon">
                <FaCamera size={12} />
              </div>
            </div>
            <div className="px-3 py-4">
              <div className="d-flex justify-content-between align-content-center flex-md-column gap-md-2 flex-lg-row">
                <p className="primary-text">{user?.username}</p>
                <button className="btn4">Copy Link</button>
              </div>
              <div className="mt-3">
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Post</p>
                  <p className="primary-text">105k</p>
                </div>
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Comment</p>
                  <p className="primary-text">2,012k</p>
                </div>
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Gold Received</p>
                  <p className="primary-text">8k</p>
                </div>
              </div>
              <hr className="my-4" />
              <div className="d-flex flex-column gap-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex topic-profile align-items-center gap-2">
                    <img
                      src="https://randomuser.me/api/portraits/men/1.jpg"
                      alt=""
                    />
                    <div>
                      <p className="small-text">Profile</p>
                      <p className="smaller-text">Customize profile</p>
                    </div>
                  </div>
                  <button className="btn4">Edit</button>
                </div>
                <div className="d-flex justify-content-between align-items-center`">
                  <p className="primary-text gray-color d-flex align-items-center gap-2">
                    <TbUserEdit size={18} /> Edit Profile
                  </p>
                  <button className="btn4">Edit</button>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="primary-text gray-color d-flex align-items-center gap-2">
                    <TbCake size={18} /> 12/25/2002
                  </p>
                  <button className="btn4">Edit</button>
                </div>
                <hr className="my-3" />
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  onClick={handleLogout}
                  className="primary-text gray-color d-flex align-items-center gap-2 cursor-pointer"
                >
                  <VscSignOut size={18} /> Log Out
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
