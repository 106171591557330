import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import API from "../../utils/axiosInstance";

const ForgotPassword = ({ show, onHide }) => {
  const [email, setEmail] = useState("");
  const [screen, setScreen] = useState("email");

  const handleSubmit = (e) => {
    e.preventDefault();
    API.post("/auth/forgot-password", { email }).then((res) => {
      setScreen("success");
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Forgot Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {screen === "email" && (
          <form onSubmit={handleSubmit}>
            <p>Please enter your email</p>
            <div className="my-3 d-none search-box s_input d-md-flex align-items-center">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button type="submit" className="btn2 w-100">
              Submit
            </button>
          </form>
        )}
        {screen === "success" && (
          <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <h3>Email sent</h3>
            <p className="text-center">
              We have sent an email to {email} with a link to reset your
              password. Please check your email.
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ForgotPassword;
