import React from "react";
import SettingLayout from "../../Components/SettingLayout";

const Policies = () => {
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Legal and Policies</h3>;
        <div className="my-3">
          <h6 className="fw-bold"> Terms of Service</h6>
          <p className="fw-semibold mt-2">
            {" "}
            This privacy policy describes how we collect, informationand what
            choices you have with respect to the information.When we refer to we
            mean our entity that acts as the controller orof your information,
            as explained in more detail in the “Identifying theData Controller
            and processor” section below.
          </p>
          <h6 className="fw-bold mt-4"> Table of content</h6>
          <ul>
            <li> Applicability of this Privacy Policy</li>
            <li> Information we collect and receive</li>
            <li> How we use information</li>
            <li>Security</li>
            <li> Data retention</li>
            <li> How we share and disclose information</li>
            <li>Security</li>
          </ul>
          <h6 className="fw-bold mt-5"> Privacy Policy</h6>
          <p className="fw-semibold mt-2">
            {" "}
            This Privacy Policy explains how we collect, use, disclose, and
            safeguard your information when you visit our website and use our
            services. It also outlines your rights regarding your information
            and how you can exercise those rights.
          </p>
          <h6 className="fw-bold mt-4"> Table of content</h6>
          <ul>
            <li> Applicability of this Privacy Policy</li>
            <li> Information We Collect</li>
            <li> How We Use Your Information</li>
            <li> How We Share and Disclose Your Information</li>
            <li> Your Choices and Rights</li>
            <li> Changes to This Privacy Policy</li>
          </ul>
          <h6 className="fw-bold mt-5"> Cookie Policy</h6>
          <p className="fw-semibold mt-2">
            This Cookie Privacy Policy explains how we use cookies and similar
            tracking technologies to collect and process information about your
            usage of our website and services. It also outlines your choices
            regarding these technologies
          </p>
          <h6 className="fw-bold mt-4"> Table of content</h6>
          <ul>
            <li> Applicability of this Cookie Policy</li>
            <li> What are Cookies?</li>
            <li> Types of Cookies We Use</li>
            <li> How We Use Cookies</li>
            <li> Managing Your Cookie Preferences</li>
            <li>Security</li>
            <li> Changes to this Cookie Policy</li>
          </ul>
        </div>
      </div>
    </SettingLayout>
  );
};

export default Policies;
