import Layout from "../Components/Layout";
import { useEffect, useRef, useState } from "react";
import Post from "../Components/Post";
import { useSelector } from "react-redux";
import API from "../utils/axiosInstance";
import SidebarContainer from "../Components/SidebarContainer";
import HeadPostContainer from "../Components/HeadPostContainer";

const Home = () => {
  const [activeTab, setActiveTab] = useState("forYou");
  const [tab, setTab] = useState("main");

  const [posts, setPosts] = useState([]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (activeTab === "following") {
      API.get(`/post/following`).then((res) => {
        setPosts(res.data?.result);
      });
    } else {
      API.get(`/post/foryou`).then((res) => {
        setPosts(res.data?.result);
      });
    }
  }, [isAuthenticated, activeTab]);

  return (
    <Layout>
      <div className="py-4 container d-flex gap-4">
        <SidebarContainer setTab={setTab} />
        <div className="col-12 col-md-9">
          <div className="d-flex align-items-center justify-content-between border-bottom-gray position-relative px-5 pb-2">
            <h5
              className={`fw-bold ${
                activeTab === "forYou" ? "text-dark" : "text_muted"
              }`}
              onClick={() => setActiveTab("forYou")}
            >
              For You
            </h5>
            <h5
              className={`fw-bold ${
                activeTab === "following" ? "text-dark" : "text_muted"
              }`}
              onClick={() => isAuthenticated && setActiveTab("following")}
            >
              Following
            </h5>

            <div
              className="position-absolute"
              style={{
                bottom: "0",
                height: "5px",
                borderRadius: "2px",
                backgroundColor: "#5ae6cb",
                width: activeTab === "forYou" ? "10%" : "13%",
                left: activeTab === "forYou" ? "5%" : "82%",
                transition: "left 0.3s ease",
              }}
            ></div>
          </div>

          {isAuthenticated && <HeadPostContainer />}

          {/* <p
            className="fw-semibold text-center mt-1 cursor-pointer"
            style={{ color: "#5ae6cb" }}
          >
            Show 105 posts
          </p> */}
          {/* <div>
            <h3 className="primary-heading">Trending Topics</h3>
            <div className="position-relative overflow-hidden trending-topics">
              <div className="navigation-buttons">
                <TbCircleArrowLeftFilled
                  size={40}
                  className="nav-btn left-btn primary-icon"
                  onClick={() => handleScroll(-250)}
                />
                <TbCircleArrowRightFilled
                  size={40}
                  className="nav-btn right-btn primary-icon"
                  onClick={() => handleScroll(250)}
                />
              </div>
              <div
                ref={scrollContainerRef}
                className="trending-topics-container mt-4 d-flex"
              >
                {Array(5)
                  .fill()
                  .map((_, index) => (
                    <TrendingTopicCard />
                  ))}
              </div>
            </div>
          </div> */}
          <div>
            {posts.map((post) => (
              <Post post={post} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
