import { useState } from "react";
import { Modal } from "react-bootstrap";
import API from "../utils/axiosInstance";

const AddPost = ({ show, onHide }) => {
  const [caption, setCaption] = useState("");
  const [hashTags, setHashTags] = useState("");
  const [image, setImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!image && !caption) return;
    let fd = { caption, hashtags: hashTags };
    // Handle form submission
    if (image) {
      fd = new FormData();
      fd.append("caption", caption);
      fd.append("hashtags", hashTags);
      fd.append("file", image);
    }
    API.post("/post", fd).then((res) => {
      setCaption("");
      setHashTags("");
      setImage(null);
      onHide();
    });
  };

  return (
    <Modal show={show} onHide={onHide}>
      <div className="p-3">
        <h4>Add Post</h4>
        <form onSubmit={handleSubmit}>
          <textarea
            className="form-control"
            placeholder="Caption..."
            rows={3}
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          ></textarea>
          <input
            type="text"
            value={hashTags}
            className="form-control mt-2"
            placeholder="Hashtags..."
            onChange={(e) => setHashTags(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            className="form-control mt-2"
          />
          <button className="btn2 mt-3 w-100">Post</button>
        </form>
      </div>
    </Modal>
  );
};

export default AddPost;
