import { CiDumbbell } from "react-icons/ci";
import {
  FaBriefcase,
  FaCar,
  FaTruckMonster,
  FaUmbrellaBeach,
} from "react-icons/fa";
import {
  MdAgriculture,
  MdBusiness,
  MdFamilyRestroom,
  MdHealthAndSafety,
  MdHowToVote,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlinePets,
} from "react-icons/md";
import { FaPeopleRoof } from "react-icons/fa6";
import {
  TbAlertSquareRounded,
  TbBadgeAd,
  TbBrandFeedly,
  TbBrandPagekit,
  TbCoins,
  TbDeviceComputerCamera,
  TbHelpSquareRounded,
  TbShoppingBagHeart,
  TbUserCircle,
} from "react-icons/tb";
import { CSSTransition } from "react-transition-group";
import { GiInjustice, GiLovers } from "react-icons/gi";
import { GrUserManager } from "react-icons/gr";
import { SiWikimediafoundation } from "react-icons/si";
import { PiBuildingApartmentFill, PiStudentFill } from "react-icons/pi";
import { BsFillMoonStarsFill } from "react-icons/bs";
import {
  IoBookSharp,
  IoFastFood,
  IoChatboxEllipsesOutline,
} from "react-icons/io5";
import { AiOutlineFire } from "react-icons/ai";
import { HiOutlineUsers } from "react-icons/hi";
import { useRef, useState } from "react";

const SidebarContainer = ({ setTab }) => {
  const nodeRef = useRef();
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="d-none col-3 sidebar d-sm-none d-md-block">
      <div className="sidebar-container">
        <div>
          <p className="primary-text mb-3">Explore</p>
          {/* <div className="d-flex align-items-center justify-content-between "> */}
          <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
            <AiOutlineFire size={18} className="icon" />
            <p className="small-text">Trending topics</p>
          </div>
          {/* <p className="small-text">20</p> */}
          {/* </div> */}
          {/* <div className="d-flex align-items-center justify-content-between "> */}
          <div
            className="d-flex gap-2 align-items-center mb-4 cursor-pointer"
            onClick={() => setTab("main")}
          >
            <HiOutlineUsers size={18} className="icon" />
            <p className="small-text">Popular Posts</p>
          </div>
          {/* <p className="small-text">18</p> */}
          {/* </div> */}
          {/* <div className="d-flex align-items-center justify-content-between "> */}
          <div className="d-flex gap-2 align-items-center mb-4 cursor-pointer">
            <IoChatboxEllipsesOutline size={18} className="icon" />
            <p className="small-text">Suggested users</p>
          </div>
          {/* <p className="small-text">22</p> */}
          {/* </div> */}
          {/* <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
          <div className="d-flex gap-2 align-items-center">
            <TbShoppingBagHeart size={18} className="icon" />
            <p className="small-text">Lifestyle</p>
          </div>
          <p className="small-text">14</p>
        </div> */}

          <hr className="horizontal-divider" />
        </div>
        <div>
          <p className="primary-text mb-3">Topic</p>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <CiDumbbell size={18} className="icon" />
              <p className="small-text">Fitness</p>
            </div>
            <p className="small-text">20</p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <FaUmbrellaBeach size={18} className="icon" />
              <p className="small-text">Travel</p>
            </div>
            <p className="small-text">18</p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbCoins size={18} className="icon" />
              <p className="small-text">Investment</p>
            </div>
            <p className="small-text">22</p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbShoppingBagHeart size={18} className="icon" />
              <p className="small-text">Lifestyle</p>
            </div>
            <p className="small-text">14</p>
          </div>
          <CSSTransition
            in={showMore}
            timeout={300}
            nodeRef={nodeRef}
            classNames="comments-section"
            unmountOnExit
          >
            <div ref={nodeRef}>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdHowToVote size={18} className="icon" />
                  <p className="small-text">Politics</p>
                </div>
                <p className="small-text">22</p>
              </div>

              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <GiInjustice size={18} className="icon" />
                  <p className="small-text">Crime</p>
                </div>
                <p className="small-text">24</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <GiLovers size={18} className="icon" />
                  <p className="small-text">Romance</p>
                </div>
                <p className="small-text">28</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <FaBriefcase size={18} className="icon" />
                  <p className="small-text">Jobs/Vacancies</p>
                </div>
                <p className="small-text">14</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <GrUserManager size={18} className="icon" />
                  <p className="small-text">Career</p>
                </div>
                <p className="small-text">10</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdBusiness size={18} className="icon" />
                  <p className="small-text">Business</p>
                </div>
                <p className="small-text">9</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <SiWikimediafoundation size={18} className="icon" />
                  <p className="small-text">NYSC</p>
                </div>
                <p className="small-text">16</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <PiStudentFill size={18} className="icon" />
                  <p className="small-text">Education</p>
                </div>
                <p className="small-text">14</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <FaTruckMonster size={18} className="icon" />
                  <p className="small-text">Autos</p>
                </div>
                <p className="small-text">17</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <FaCar size={18} className="icon" />
                  <p className="small-text">Car Talk</p>
                </div>
                <p className="small-text">15</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <PiBuildingApartmentFill size={18} className="icon" />
                  <p className="small-text">Properties</p>
                </div>
                <p className="small-text">17</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdHealthAndSafety size={18} className="icon" />
                  <p className="small-text">Health</p>
                </div>
                <p className="small-text">19</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdFamilyRestroom size={18} className="icon" />
                  <p className="small-text">Family</p>
                </div>
                <p className="small-text">17</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <FaPeopleRoof size={18} className="icon" />
                  <p className="small-text">Culture</p>
                </div>
                <p className="small-text">12</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <BsFillMoonStarsFill size={18} className="icon" />
                  <p className="small-text">Religion</p>
                </div>
                <p className="small-text">13</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <IoFastFood size={18} className="icon" />
                  <p className="small-text">Food</p>
                </div>
                <p className="small-text">14</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <IoBookSharp size={18} className="icon" />
                  <p className="small-text">Diaries</p>
                </div>
                <p className="small-text">15</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdOutlinePets size={18} className="icon" />
                  <p className="small-text">Pets</p>
                </div>
                <p className="small-text">15</p>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
                <div className="d-flex gap-2 align-items-center">
                  <MdAgriculture size={18} className="icon" />
                  <p className="small-text">Agriculture</p>
                </div>
                <p className="small-text">14</p>
              </div>
            </div>
          </CSSTransition>
          <div
            onClick={() => setShowMore(!showMore)}
            className="d-flex align-items-center cursor-pointer mb-4"
          >
            <p className="small-text">{showMore ? "Hide" : "See"} More</p>
            {showMore ? (
              <MdKeyboardArrowUp size={18} className="icon" />
            ) : (
              <MdKeyboardArrowDown size={18} className="icon" />
            )}
          </div>
          <hr className="horizontal-divider" />
        </div>

        <div>
          <p className="primary-text mb-3">Feeds</p>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbBrandFeedly size={18} className="icon" />
              <p className="small-text">Topic Feeds</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbBrandPagekit size={18} className="icon" />
              <p className="small-text">Topic Pages</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbUserCircle size={18} className="icon" />
              <p className="small-text">User Profiles</p>
            </div>
          </div>
          <hr className="horizontal-divider" />
        </div>

        <div>
          <p className="primary-text mb-3">Resources</p>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbAlertSquareRounded size={18} className="icon" />
              <p className="small-text">About Backyard</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbBadgeAd size={18} className="icon" />
              <p className="small-text">Adevertise</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbHelpSquareRounded size={18} className="icon" />
              <p className="small-text">Help</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
            <div className="d-flex gap-2 align-items-center">
              <TbDeviceComputerCamera size={18} className="icon" />
              <p className="small-text">Careers</p>
            </div>
          </div>
          {/* <hr className="horizontal-divider" /> */}
        </div>
        {/* <div>
        <p className="primary-text mb-3">Profile</p>
        <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
          <div className="profile-box d-flex gap-2 align-items-center cursor-pointer">
            <img
              src="https://randomuser.me/api/portraits/men/1.jpg"
              alt=""
            />
            <div>
              <p className="primary-text">Devine Jack</p>
              <p className="small-text">Fitness</p>
            </div>
          </div>
          <FaCirclePlus
            size={30}
            className="primary-icon cursor-pointer"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
          <div className="profile-box d-flex gap-2 align-items-center cursor-pointer">
            <img
              src="https://randomuser.me/api/portraits/women/3.jpg"
              alt=""
            />
            <div>
              <p className="primary-text">Jenny Wilson</p>
              <p className="small-text">Fitness</p>
            </div>
          </div>
          <FaCirclePlus
            size={30}
            className="primary-icon cursor-pointer"
          />
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
          <div className="profile-box d-flex gap-2 align-items-center cursor-pointer">
            <img
              src="https://randomuser.me/api/portraits/men/2.jpg"
              alt=""
            />
            <div>
              <p className="primary-text">Robert Fox</p>
              <p className="small-text">Fitness</p>
            </div>
          </div>
          <FaCirclePlus
            size={30}
            className="primary-icon cursor-pointer"
          />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-4 cursor-pointer">
          <div className="profile-box d-flex gap-2 align-items-center cursor-pointer">
            <img
              src="https://randomuser.me/api/portraits/men/3.jpg"
              alt=""
            />
            <div>
              <p className="primary-text">Jacob Jones</p>
              <p className="small-text">Fitness</p>
            </div>
          </div>
          <FaCirclePlus
            size={30}
            className="primary-icon cursor-pointer"
          />
        </div>
        <div className="d-flex align-items-center cursor-pointer">
          <p className="small-text text-black">See More</p>
          <MdKeyboardArrowDown size={18} className="icon" />
        </div>
      </div> */}
      </div>
      {/* <div className="sidebar-container2 mt-5">
      <h3 className="primary-heading">Popular Topics</h3>
      <div className="my-4">
        <div className="img-container">
          <img src="https://picsum.photos/900" alt="" />
          <div className="floating-text">Fitness</div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2 pe-2">
          <div className="d-flex topic-profile align-items-center gap-2">
            <img
              src="https://randomuser.me/api/portraits/men/1.jpg"
              alt=""
            />
            <p className="small-text">Cody Fisher</p>
          </div>
          <div className="divider"></div>
          <p className="small-text">13 May, 2024</p>
        </div>
        <h4 className="secondary-heading mt-2">
          Latest updates on workouts, health trends.
        </h4>
      </div>
      <div className="my-4">
        <div className="img-container">
          <img src="https://picsum.photos/900" alt="" />
          <div className="floating-text">Fitness</div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2 pe-2">
          <div className="d-flex topic-profile align-items-center gap-2">
            <img
              src="https://randomuser.me/api/portraits/men/1.jpg"
              alt=""
            />
            <p className="small-text">Cody Fisher</p>
          </div>
          <div className="divider"></div>
          <p className="small-text">13 May, 2024</p>
        </div>
        <h4 className="secondary-heading mt-2">
          Latest updates on workouts, health trends.
        </h4>
      </div>
      <div className="my-4">
        <div className="img-container">
          <img src="https://picsum.photos/900" alt="" />
          <div className="floating-text">Fitness</div>
        </div>
        <div className="d-flex align-items-center justify-content-between mt-2 pe-2">
          <div className="d-flex topic-profile align-items-center gap-2">
            <img
              src="https://randomuser.me/api/portraits/men/1.jpg"
              alt=""
            />
            <p className="small-text">Cody Fisher</p>
          </div>
          <div className="divider"></div>
          <p className="small-text">13 May, 2024</p>
        </div>
        <h4 className="secondary-heading mt-2">
          Latest updates on workouts, health trends.
        </h4>
      </div>
    </div> */}
    </div>
  );
};

export default SidebarContainer;
