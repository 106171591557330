import { PiShareFatBold } from "react-icons/pi";
import {
  TbDownload,
  TbMessageCircle,
  TbThumbDown,
  TbThumbUp,
} from "react-icons/tb";
import Layout from "../Components/Layout";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { IoIosSearch, IoIosSend } from "react-icons/io";
import Comment from "../Components/Comment";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import NumberFormatter from "../Components/NumberFormatter";
import API from "../utils/axiosInstance";

const Topic = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState("");

  useEffect(() => {
    API.get(`/post/s/${id}`).then((res) => {
      setPost(res.data.result);
    });
  }, [id]);

  const handleLike = () => {
    API.put(`post/s/${id}`).then((res) => {
      if (post.isLiked) {
        setPost({ ...post, likes: post?.likes - 1, isLiked: false });
      } else {
        setPost({ ...post, likes: post?.likes + 1, isLiked: true });
      }
    });
  };

  const addComment = (e) => {
    e.preventDefault();
    API.patch(`/post/s/${post?.id}`, { comment }).then((res) => {
      setPost({ ...post, comments: [...post.comments, res.data.result] });
      setComment("");
    });
  };

  return (
    <Layout>
      <div className="container my-sm-5 my-2 gap-4 d-flex">
        <div className="col-12 col-md-9">
          <div className="mt-4">
            <div className="d-flex align-items-center gap-3 pe-2">
              <div className="d-flex topic-profile align-items-center gap-2">
                <img src={post?.user?.profile} alt="" />
                <p className="small-text">
                  {post?.user?.firstName} {post?.user?.lastName}
                </p>
              </div>
              <div className="divider"></div>
              <p className="small-text">
                {moment(post?.createdAt).format("DD MMM, YYYY")}
              </p>
            </div>
            <h3 className="primary-heading my-2">{post?.caption}</h3>
            {post?.image && (
              <div className="post-pic">
                <img src={post?.image} alt="" />
              </div>
            )}
            <p className="primary-text font-weight-bold my-3">
              {post?.hashtags}
            </p>
            <div className="post-actions d-flex justify-content-between align-items-center py-2">
              <div className="d-flex align-items-center gap-3">
                <p
                  onClick={handleLike}
                  className={`small-text d-flex align-items-center gap-1 cursor-pointer primary-hover ${
                    post?.isLiked && "primary-color"
                  }`}
                >
                  <TbThumbUp size={18} />{" "}
                  <NumberFormatter number={post?.likes} />
                </p>
                <p className="small-text d-flex align-items-center gap-2 cursor-pointer primary-hover">
                  <TbThumbDown size={18} />
                </p>

                <p className="small-text d-flex align-items-center gap-1 cursor-pointer primary-hover">
                  <TbMessageCircle size={18} />{" "}
                  <NumberFormatter number={post?.comments?.length} />
                </p>
              </div>
              <div className="d-flex align-items-center gap-3">
                <p className="small-text cursor-pointer primary-hover">
                  <PiShareFatBold size={18} />
                </p>
                <p className="small-text cursor-pointer primary-hover">
                  <TbDownload size={18} />
                </p>
              </div>
            </div>
          </div>
          <div className="sponsor-block mt-4">
            <div className="d-flex topic-profile align-items-center gap-2">
              <img src="https://randomuser.me/api/portraits/men/1.jpg" alt="" />
              <p className="small-text">
                Domen Huden <span>Sponsored</span>
              </p>
            </div>
            <p className="small-text my-2">
              The most beginner friendly place to start your coding jumpy. Learn
              the fundamentals of Software Development and Data Science at your
              own pace. explore programming fields, and ask questions to
              industry leading instructors.
            </p>
            <div className="sponsor-images d-flex align-items-center gap-3 flex-wrap">
              <img src="https://picsum.photos/900" alt="" />
              <img src="https://picsum.photos/1000" alt="" />
              <img src="https://picsum.photos/1100" alt="" />
              <img src="https://picsum.photos/1200" alt="" />
            </div>
          </div>
          <hr />
          <div className="comments-block">
            <div className="d-flex justify-content-between align-items-sm-center flex-column gap-2 gap-sm-0 flex-sm-row">
              <div className="d-flex align-items-center gap-3 justify-content-between justify-content-md-start">
                <div className="d-flex align-items-center cursor-pointer sort-dropdown gap-1">
                  <p className="small-text">New</p>
                  <MdKeyboardArrowDown size={20} className="icon" />
                </div>
                <button className="btn3 d-flex align-items-center gap-2">
                  <FiPlus size={19} /> Create a post
                </button>
              </div>
              <div className="search-box d-flex align-items-center search-comments">
                <IoIosSearch size={22} className="icon" />
                <input type="text" placeholder="Search Comments" />
              </div>
            </div>
            <div className="mt-4">
              {post?.comments
                ?.filter((comment) => !comment.repliedTo)
                ?.map((comment, i) => (
                  <Comment
                    comment={comment}
                    key={i}
                    comments={post?.comments}
                    setPost={setPost}
                    postId={post?.id}
                  />
                ))}
            </div>
            <form
              onSubmit={addComment}
              className="mt-2 d-flex align-items-center gap-2 border-1 px-2 rounded-1"
              style={{ border: "1px solid black" }}
            >
              <input
                type="text"
                className="border-0 w-100 py-2"
                placeholder="Write a comment"
                style={{ outline: "none" }}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              />
              <button
                type="submit"
                className="cursor-pointer bg-transparent border-0"
              >
                <IoIosSend size={22} />
              </button>
            </form>
          </div>
        </div>
        <div className="d-none col-3 d-md-block">
          <div className="profile-card">
            <div className="profile-card-img cursor-pointer">
              <img src={post?.user?.profile} alt="" />
            </div>
            <div className="px-3 py-4">
              <p className="primary-text">relph_edward</p>
              <button className="btn4 bg-primary px-3 py-1 mt-2 text-white">
                Follow
              </button>
              <div className="mt-2">
                <p className="small-text">
                  By Feds, For Feds: Serving Civilian Federal Employees Since
                  1943. Trust Worldwide Assurance For Employees of Public
                  Agencies. Visit waepa.org.
                </p>
              </div>
              <hr className="my-4" />
              <div className="mt-3">
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Post</p>
                  <p className="primary-text">
                    <NumberFormatter number={10000} />
                  </p>
                </div>
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Comment</p>
                  <p className="primary-text">2,012k</p>
                </div>
                <div className="d-flex profile-card-box my-2 justify-content-between align-content-center">
                  <p className="primary-text gray-color">Gold Received</p>
                  <p className="primary-text">8k</p>
                </div>
              </div>
              <hr className="my-4" />
              <div>
                <p className="primary-text">Links</p>
                <div className="d-flex gap-2 flex-wrap mt-2">
                  <button className="btn4 px-2 py-1 text-dark">Facebook</button>
                  <button className="btn4 px-2 py-1 text-dark">LinkedIn</button>
                  <button className="btn4 px-2 py-1 text-dark">Twitter</button>
                  <button className="btn4 px-2 py-1 text-dark">
                    Instagram
                  </button>
                  <button className="btn4 px-2 py-1 text-dark">YouTube</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mb-5">
        <button className="btn3 text-dark px-5">Show More</button>
      </div>
    </Layout>
  );
};

export default Topic;
