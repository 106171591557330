import React, { useEffect, useRef, useState } from "react";
import Layout from "../Components/Layout";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { RiVideoAddFill } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { FaVideo, FaCirclePlus, FaPhone } from "react-icons/fa6";
import { MdInfo } from "react-icons/md";
import { HiGif, HiOutlineBell } from "react-icons/hi2";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoMdImages } from "react-icons/io";
import { PiStickerLight } from "react-icons/pi";
import { SlSocialFacebook } from "react-icons/sl";
import { useParams } from "react-router-dom";
import UsersList from "../Components/Modals/UsersList";
import ConversationsUser from "../Components/ConversationsUser";
import API from "../utils/axiosInstance";
import { socket } from "../utils/socket";
import MessagesBox from "../Components/MessagesBox";
import moment from "moment";
import { useSelector } from "react-redux";

const Messages = () => {
  const { id } = useParams();
  const { user: loggedinUser } = useSelector((state) => state.auth);
  const [activeTab, setActiveTab] = useState("inbox");
  const [activeSection, setActiveSection] = useState(null);
  const [showUsersModal, setUsersModal] = useState(false);
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [message, setMessage] = useState("");
  const [currentConversation, setCurrentConversation] = useState(null);
  const [sortedConversations, setSortedConversations] = useState([]);

  useEffect(() => {
    let con = [];
    API.get(`/chat`)
      .then((res) => {
        setConversations(res.data);
        con = res.data;
      })
      .then(() => {
        if (id) {
          API.get(`/chat/user/${id}`).then((res) => {
            setMessages(res.data?.messages);
            setUser(res.data?.userInfo);

            const existing = con.find(
              (c) => c.id === res.data?.conversation.id
            );
            if (!existing) {
              setConversations([...con, res.data?.conversation]);
              socket.emit("joinRooms", [res.data?.conversation.id]);
            }
          });
        }
      });
  }, [id]);

  useEffect(() => {
    if (id && conversations?.length > 0) {
      const c = conversations.find((cc) => cc?.userDetails?.id == id);
      if (c.unreadCount > 0) {
        clearConversationUnreads(c.id);
        socket.emit("clearUnreads", { id: c.id });
      }
      setCurrentConversation(c.id);
    }
  }, [id, conversations]);

  useEffect(() => {
    if (conversations?.length) {
      const sorted = [...conversations].sort((a, b) => {
        // Check if subField and createdAt exist, otherwise use the main createdAt
        const dateA = a?.lastMessage?.createdAt
          ? new Date(a?.lastMessage?.createdAt)
          : new Date(a.createdAt);
        const dateB = b?.lastMessage?.createdAt
          ? new Date(b?.lastMessage?.createdAt)
          : new Date(b.createdAt);

        // Sort in descending order (newest first)
        return dateB - dateA;
      });
      setSortedConversations(sorted);
    }
  }, [conversations]);

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  useEffect(() => {
    if (socket) {
      socket.on("receiveMessage", (data) => {
        if (data.conversation == currentConversation) {
          setMessages((prev) => [...prev, data]);
          if (data.sender != loggedinUser.id) {
            socket.emit("clearUnreads", { id: currentConversation });
          }
        } else {
          addConversationUnreads(data.conversation);
        }
        setLastMessage(data);
      });
      socket.on("userDisconnected", (data) => {
        const c = conversations.find(
          (cc) => cc?.userDetails?.id == data?.userId
        );
        if (c) {
          setConversations((prev) => {
            const remainingConversations = prev.filter(
              (c) => c.userDetails?.id != data?.userId
            );
            c.userDetails.isOnline = false;
            c.userDetails.lastOnline = Date.now();

            return [...remainingConversations, c];
          });
        }
        setUser((user) => {
          if (user?.id == data.userId) {
            return { ...user, isOnline: false, lastOnline: new Date() };
          } else {
            return user;
          }
        });
      });

      socket.on("userConnected", (data) => {
        const c = conversations.find(
          (cc) => cc?.userDetails?.id == data?.userId
        );
        if (c) {
          setConversations((prev) => {
            const remainingConversations = prev.filter(
              (c) => c?.userDetails?.id != data?.userId
            );
            c.userDetails.isOnline = true;
            c.userDetails.lastOnline = Date.now();
            return [...remainingConversations, c];
          });
        }
        setUser((user) => {
          if (user?.id == data.userId) {
            return { ...user, isOnline: true, lastOnline: Date.now() };
          } else {
            return user;
          }
        });
      });

      return () => {
        socket.off("receiveMessage");
        socket.off("userDisconnected");
        socket.off("userConnected");
      };
    }
  }, [socket, conversations, currentConversation]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    socket.emit("sendMessage", { message, conversation: currentConversation });
    setMessage("");
  };

  const setLastMessage = (m) => {
    setConversations((prev) => {
      const currentCon = prev.find((c) => c.id == m.conversation);
      const remainingConversations = prev.filter(
        (c) => c?.id != m.conversation
      );

      currentCon.lastMessage = m;
      return [...remainingConversations, currentCon];
    });
  };

  const clearConversationUnreads = (con) => {
    setConversations((prev) => {
      const currentCon = prev.find((c) => c.id == con);
      const remainingConversations = prev.filter((c) => c?.id != con);

      currentCon.unreadCount = 0;
      return [...remainingConversations, currentCon];
    });
  };

  const addConversationUnreads = (con) => {
    setConversations((prev) => {
      const currentCon = prev.find((c) => c.id == con);
      const remainingConversations = prev.filter((c) => c?.id != con);

      currentCon.unreadCount += 1;
      return [...remainingConversations, currentCon];
    });
  };

  // console.log(user);

  return (
    <Layout>
      <UsersList show={showUsersModal} onHide={() => setUsersModal(false)} />
      <div className="container pt-5 vh-100">
        <div className="row messages-container">
          {/* User List Section */}
          <div className="col-12 col-md-3 border-end ">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="fw-bold">Chats</h5>
              <div className="d-flex align-items-center gap-2">
                <div className="chat-options">
                  <HiOutlineDotsHorizontal size={17} />
                </div>
                <div className="chat-options">
                  <RiVideoAddFill size={17} />
                </div>
                <div
                  className="chat-options"
                  onClick={() => setUsersModal(true)}
                >
                  <FaEdit size={17} />
                </div>
              </div>
            </div>
            <div className="d-none search-box px-2 w-100 chat-search d-md-flex align-items-center">
              <FiSearch size={26} className="icon fw-bold" />
              <input
                type="text"
                placeholder="Search message"
                className="bg-transparent fw-normal"
              />
            </div>

            <div className="d-flex align-items-center gap-4 my-4">
              <button
                className={`chat_inbox btn fw-semibold ${
                  activeTab === "inbox" ? "bg-light-primary primary-color" : ""
                }`}
                onClick={() => toggleTab("inbox")}
              >
                Inbox
              </button>
              <button
                className={`chat_inbox btn fw-semibold ${
                  activeTab === "communities"
                    ? "bg-light-primary primary-color"
                    : ""
                }`}
                onClick={() => toggleTab("communities")}
              >
                Communities
              </button>
            </div>

            {activeTab === "inbox" ? (
              <div>
                {sortedConversations?.map((c, i) => (
                  <ConversationsUser key={i} conversation={c} />
                ))}
              </div>
            ) : (
              <div>
                <h5>Communities</h5>
                <p>Here you can display the communities section.</p>
              </div>
            )}
          </div>
          {id ? (
            <>
              {/* Chat Details Section */}
              <div className="col-12 col-md-6 bg-white d-flex flex-column h-100 ">
                {user && (
                  <div className="d-flex flex-column h-100">
                    <div className="d-flex justify-content-between align-items-center p-3 chat-head">
                      <div className="d-flex gap-2 align-items-center">
                        <img
                          style={{
                            width: "2.2rem",
                            height: "2.2rem",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={user?.profile}
                          alt={user?.firstName}
                        />
                        <div>
                          <p className="mb-0 mt-0 fw-bold">
                            {user?.firstName} {user?.lastName}
                          </p>
                          {user?.isOnline ? (
                            <p className="continue mb-0">Active now</p>
                          ) : (
                            <p className="continue mb-0">
                              Active {moment(user?.lastOnline).fromNow()}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-3">
                        <button className="border-0 bg-none">
                          <FaPhone className="primary-color" size={16} />
                        </button>
                        <button className="border-0 bg-none">
                          <FaVideo className="primary-color" size={20} />
                        </button>
                        <button className="border-0 bg-none">
                          <MdInfo className="primary-color" size={20} />
                        </button>
                      </div>
                    </div>
                    {/* <div className="flex flex-col"> */}
                    <MessagesBox messages={messages} />
                    {/* </div> */}

                    {/* Chat search bar */}
                    <div className="d-flex align-items-center mt-2 gap-3  py-2 bg-white">
                      <div className="d-flex align-items-center gap-2">
                        <button className="border-0 bg-none">
                          <FaCirclePlus className="primary-color" size={20} />
                        </button>
                        <button className="border-0 bg-none">
                          <IoMdImages className="primary-color" size={22} />
                        </button>
                        <button className="border-0 bg-none">
                          <PiStickerLight className="primary-color" size={22} />
                        </button>
                        <button className="border-0 bg-none">
                          <HiGif className="primary-color" size={24} />
                        </button>
                      </div>
                      <form
                        onSubmit={handleSendMessage}
                        className="d-flex message-box px-2 w-100 chat-search align-items-center"
                      >
                        <input
                          type="text"
                          placeholder="Aa"
                          className="bg-transparent fw-normal form-control outline-0"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <button
                          type="submit"
                          className="btn bg-primary text-white fw-semibold px-3"
                        >
                          Send
                        </button>
                      </form>
                    </div>
                  </div>
                )}
              </div>

              {/* User Profile Section */}
              <div className="col-12 col-md-3  ps-4">
                {user && (
                  <div>
                    <div className="d-flex justify-content-center align-items-center flex-column ">
                      <img
                        style={{
                          width: "4rem",
                          height: "4rem",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                        src={user?.profile}
                        alt={user?.firstName}
                      />{" "}
                      <p className=" fw-bold mb-0 mt-2">
                        {user?.firstName} {user?.lastName}
                      </p>
                      {user?.isOnline ? (
                        <p className="continue mb-0">Active now</p>
                      ) : (
                        <p className="continue mb-0">
                          Active {moment(user?.lastOnline).fromNow()}
                        </p>
                      )}
                      <div className="d-flex align-items-center gap-4 mt-4">
                        <div className="cursor-pointer">
                          <div
                            className="bg-light-gray d-flex justify-content-center align-items-center "
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                            }}
                          >
                            <SlSocialFacebook size={20} />
                          </div>
                          <p className="continue">Profile</p>
                        </div>{" "}
                        <div className="cursor-pointer">
                          <div
                            className="bg-light-gray  d-flex justify-content-center align-items-center "
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                            }}
                          >
                            <HiOutlineBell size={20} />
                          </div>
                          <p className="continue">Mute</p>
                        </div>{" "}
                        <div className="cursor-pointer">
                          <div
                            className="bg-light-gray  d-flex justify-content-center align-items-center "
                            style={{
                              width: "2rem",
                              height: "2rem",
                              borderRadius: "50%",
                            }}
                          >
                            <FiSearch size={20} />
                          </div>
                          <p className="continue">Search</p>
                        </div>
                      </div>
                      {/* <div className="w-100 mt-4">
                    <div className="d-flex justify-content-between align-items-center cursor-pointer">
                      <label className="fw-semibold" htmlFor="">
                        Chat Info
                      </label>
                      <IoIosArrowDown size={20} />
                    </div>
                    <div className="d-flex justify-content-between align-items-center cursor-pointer mt-1">
                      <label className="fw-semibold" htmlFor="">
                        Customize Chat
                      </label>
                      <IoIosArrowDown size={20} />
                    </div>{" "}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer mt-1">
                      <label className="fw-semibold" htmlFor="">
                        Media, files and links
                      </label>
                      <IoIosArrowDown size={20} />
                    </div>{" "}
                    <div className="d-flex justify-content-between align-items-center cursor-pointer mt-1">
                      <label className="fw-semibold" htmlFor="">
                        Privacy & support
                      </label>
                      <IoIosArrowDown size={20} />
                    </div>
                  </div> */}
                      <div className="w-100 mt-4">
                        <div className="accordion-item">
                          <div
                            className="d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => toggleSection("chatInfo")}
                          >
                            <label className="fw-semibold">Chat Info</label>
                            {activeSection === "chatInfo" ? (
                              <IoIosArrowUp size={20} />
                            ) : (
                              <IoIosArrowDown size={20} />
                            )}
                          </div>
                        </div>

                        {/* Customize Chat Section */}
                        <div className="accordion-item mt-1">
                          <div
                            className="d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => toggleSection("customizeChat")}
                          >
                            <label className="fw-semibold">
                              Customize Chat
                            </label>
                            {activeSection === "customizeChat" ? (
                              <IoIosArrowUp size={20} />
                            ) : (
                              <IoIosArrowDown size={20} />
                            )}
                          </div>
                        </div>

                        {/* Media, files and links Section */}
                        <div className="accordion-item mt-1">
                          <div
                            className="d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => toggleSection("mediaFiles")}
                          >
                            <label className="fw-semibold">
                              Media, files and links
                            </label>
                            {activeSection === "mediaFiles" ? (
                              <IoIosArrowUp size={20} />
                            ) : (
                              <IoIosArrowDown size={20} />
                            )}
                          </div>
                        </div>

                        {/* Privacy & support Section */}
                        <div className="accordion-item mt-1">
                          <div
                            className="d-flex justify-content-between align-items-center cursor-pointer"
                            onClick={() => toggleSection("privacySupport")}
                          >
                            <label className="fw-semibold">
                              Privacy & support
                            </label>
                            {activeSection === "privacySupport" ? (
                              <IoIosArrowUp size={20} />
                            ) : (
                              <IoIosArrowDown size={20} />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className="col-md-9 d-flex justify-content-center align-items-center">
              <h2>Please select any chat</h2>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Messages;
