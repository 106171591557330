import React from "react";
import { Link } from "react-router-dom";

const UserInfo = ({ user, onHide }) => {
  return (
    <Link
      to={`/chat/${user?.user?.id}`}
      onClick={onHide}
      className="d-flex topic-profile align-items-center gap-2"
    >
      <img className="" src={user?.user?.profile} alt="" />
      <p className="small-text fw-bold">
        {user?.user?.firstName} {user?.user?.lastName}
      </p>
    </Link>
  );
};

export default UserInfo;
