import moment from "moment/moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

const ConversationsUser = ({ conversation }) => {
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();
  const isUnread = conversation.unreadCount > 0;

  return (
    <Link
      to={`/chat/${conversation?.userDetails?.id}`}
      className={`d-flex gap-2 align-items-center mt-3 w-100 p-2 rounded  ${
        id == conversation?.userDetails?.id ? "bg-light-gray" : ""
      }`}
      style={{ cursor: "pointer" }}
    >
      <div className="position-relative col-2">
        <img
          className="chats-img"
          src={conversation?.userDetails?.profile}
          alt={conversation?.userDetails?.firstName}
        />
        {conversation?.userDetails?.isOnline ? (
          <div className="online-sign"></div>
        ) : (
          <div className="offline-sign"></div>
        )}
      </div>
      <div className="col-10 px-1">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <p className="mb-0 fw-bold">
            {" "}
            {conversation?.userDetails?.firstName}{" "}
            {conversation?.userDetails?.lastName}
          </p>
          <div className="d-flex align-items-center gap-2">
            {conversation?.lastMessage && (
              <span className="fw-semibold continue">
                {moment(conversation?.lastMessage?.createdAt).format("LT")}
              </span>
            )}
            <span
              className={`${isUnread ? "bg-primary" : "bg-secondary"}`}
              style={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
              }}
            ></span>
          </div>
        </div>
        {conversation?.lastMessage && (
          <p
            className={`primary-text fs-14 truncate-text ${
              isUnread ? "fw-bold" : "fw-semibold"
            }`}
            style={{ color: "#747474" }}
          >
            {conversation?.lastMessage?.sender == user?.id ||
            conversation?.lastMessage?.sender?.id == user?.id
              ? "You: "
              : ""}{" "}
            {conversation?.lastMessage?.message}
          </p>
        )}
      </div>
    </Link>
  );
};

export default ConversationsUser;
