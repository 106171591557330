import { io } from "socket.io-client";
import store from "../redux/store";

let socket;

store.subscribe(() => {
  const state = store.getState();
  const token = state.auth?.tokens?.access?.token;

  if (token && !socket) {
    socket = io("ws://localhost:5000", {
      query: {
        token,
      },
    });
  }
});

export { socket };
