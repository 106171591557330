import React, { useState } from "react";
import SettingLayout from "../../Components/SettingLayout";
import { RiUserForbidLine } from "react-icons/ri";
import Dropdown from "../../Components/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../redux/slices/authSlice";
import API from "../../utils/axiosInstance";

const PREFERENCES = [
  { label: "All notifications", value: "all" },
  { label: "Mentions and replies only", value: "mentions" },
  { label: "Direct messages only", value: "direct" },
  { label: "Off", value: "off" },
];

const Notification = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const updateNotificationPreferences = (preference) => {
    API.post("/user/updateNotifications", {
      notificationPreferences: preference,
    }).then((res) => {
      dispatch(
        updateData({
          notifications: {
            ...user.notifications,
            notificationPreferences: preference,
          },
        })
      );
    });
  };

  const filterList = () => {
    const data = PREFERENCES.find(
      (p) => p.value === user.notifications.notificationPreferences
    );

    return data?.label;
  };

  const updateNotifications = (type) => {
    let data = {};
    switch (type) {
      case "email":
        data = { email: !user?.notifications?.email };
        break;
      case "sms":
        data = { sms: !user?.notifications?.sms };
        break;
      case "push":
        data = { push: !user?.notifications?.push };

      default:
        break;
    }

    API.post("/user/updateNotifications", data).then((res) => {
      dispatch(
        updateData({ notifications: { ...user.notifications, ...data } })
      );
    });
  };

  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading">Notification Settings</h3>
        <div className="my-4">
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2">
              <RiUserForbidLine />
              <label
                for="exampleFormControlInput1"
                class="form-label small-text "
              >
                Notification Prefrence
              </label>
            </div>
            <Dropdown
              label={"Select Notification Prefrence..."}
              items={PREFERENCES}
              value={filterList()}
              onChange={updateNotificationPreferences}
            />
          </div>
          <div className="mb-4">
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                Email Notification...{" "}
              </label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={user?.notifications?.email}
                    onChange={() => updateNotifications("email")}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
          <div className="mb-4">
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                SMS Notification...{" "}
              </label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={user?.notifications?.sms}
                    onChange={() => updateNotifications("sms")}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>{" "}
          <div>
            <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
              <label className="cursor-pointer  w-100 ">
                Push Notification...{" "}
              </label>
              <div className="toggle-container">
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={user?.notifications?.push}
                    onChange={() => updateNotifications("push")}
                  />
                  <span className="slider round"></span>
                </label>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default Notification;
