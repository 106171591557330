import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import Profile from "./Profile";
import Topic from "./Topic";
import ProfileSetting from "./setting/ProfileSetting";
import Signup from "./Signup";
import Signin from "./Signin";
import Account from "./setting/Account";
import Notification from "./setting/Notification";
import ContentPreferences from "./setting/ContentPreferences";
import Appearance from "./setting/Appearance";
import SubscriptionBilling from "./setting/SubscriptionBilling";
import ConnectedApps from "./setting/ConnectedApps";
import Privacy from "./setting/Privacy";
import Language from "./setting/Language";
import Policies from "./setting/Policies";
import HelpCenter from "./setting/HelpCenter";
import { useSelector } from "react-redux";
import Messages from "./Messages";
import ResetPassword from "./ResetPassword";

const CRoutes = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  return (
    <>
      {isAuthenticated ? (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/topic/:id" element={<Topic />} />
          <Route path="/settings" element={<ProfileSetting />} />
          <Route path="/settings/account" element={<Account />} />
          <Route path="/settings/notification" element={<Notification />} />
          <Route
            path="/settings/content-preferences"
            element={<ContentPreferences />}
          />
          <Route path="/settings/appearance" element={<Appearance />} />
          <Route
            path="/settings/subscription-and-billing"
            element={<SubscriptionBilling />}
          />
          <Route path="/settings/connected-apps" element={<ConnectedApps />} />
          <Route path="/settings/privacy-and-security" element={<Privacy />} />
          <Route path="/settings/language-and-region" element={<Language />} />
          <Route path="/settings/legal-and-policies" element={<Policies />} />
          <Route path="/settings/help-and-support" element={<HelpCenter />} />
          <Route path="/chat" element={<Messages />} />
          <Route path="/chat/:id" element={<Messages />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/signup" element={<Signup />} />
          <Route path="/login" element={<Signin />} />
          <Route path="/" element={<Home />} />
          <Route path="/topic/:id" element={<Topic />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </>
  );
};

export default CRoutes;
