import { useState } from "react";
import { followUserService } from "../services/userService";

const SearchedUser = ({ user }) => {
  const [isFollowing, setFollowing] = useState(user.isFollowed);
  const handleFollowUser = () => {
    followUserService(user._id).then((res) => {
      setFollowing(!isFollowing);
    });
  };

  return (
    <div className="d-flex align-items-center justify-content-between px-2 my-1">
      <div className="d-flex align-items-center gap-2">
        <img src={user.profile} alt="" className="searched-user-img" />
        <p className="searched-user-name fw-bold m-0">
          {user.firstName} {user.lastName}
        </p>
      </div>

      <button className="btn5" onClick={handleFollowUser}>
        {isFollowing ? "Following" : "Follow"}
      </button>
    </div>
  );
};

export default SearchedUser;
