import React from "react";
import SettingLayout from "../../Components/SettingLayout";
import { IoIosHelpCircleOutline } from "react-icons/io";
import FAQs from "../../Components/FAQs";
import { IoIosSearch } from "react-icons/io";
import { SlPhone } from "react-icons/sl";
import { GoPencil } from "react-icons/go";
import { PiDeviceMobileLight } from "react-icons/pi";
import { PiBellRinging } from "react-icons/pi";
import { MdKeyboardArrowDown } from "react-icons/md";

const HelpCenter = () => {
  const faqData = [
    {
      question: " What is the best workout routine for beginners?",
      answer:
        " For beginners, a balanced workout routine that includes a mix of cardio, strength training, and flexibility exercises is recommended. Start with 2-3 days of strength training, 2-3 days of cardio, and incorporate stretching or yoga to improve flexibility. Always consult a fitness professional before starting a new program",
    },
    {
      question: "How can I stay motivated to exercise regularly?",
      answer: "answer2",
    },
    {
      question: " What should I eat before and after a workout?",
      answer: "answer3",
    },
    {
      question: " How often should I exercise to see results?",
      answer: "answer4",
    },
  ];
  return (
    <SettingLayout>
      <div className="profile-settings">
        <h3 className="primary-heading"> Help Center and Support</h3>;
        <div className="my-4">
          {/* faq  */}
          <div className="mb-4">
            <div className="d-flex align-items-center gap-2 ms-2 mb-2">
              <IoIosHelpCircleOutline size={20} />
              <label class="form-label fw-semibold m-0 "> FAQs</label>
            </div>
            <div>
              {faqData.map((faq, index) => (
                <FAQs key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>{" "}
          {/* contact support  */}
          <div>
            <div className="d-flex align-items-center gap-2 ms-2 mb-2 border-bottom-gray pb-2">
              <IoIosSearch size={20} className="icon" />
              <label class="form-label fw-semibold m-0 ">
                {" "}
                Contact Support
              </label>
            </div>
            <div class="mb-4 w-100 mt-4">
              <div className="d-flex align-items-center gap-2 ms-2">
                <SlPhone />
                <label
                  for="exampleFormControlInput1"
                  class="form-label small-text "
                >
                  Contact Number
                </label>
              </div>

              <input
                type="text"
                className="  w-100 mt-1 profile-edit"
                id="exampleFormControlInput1"
                placeholder=" Enter your contact number"
              />
            </div>
            <div>
              <div className="d-flex align-items-center gap-2 ms-2">
                <GoPencil />

                <label
                  for="exampleFormControlTextarea1"
                  class="form-label small-text "
                >
                  Message{" "}
                </label>
              </div>

              <textarea
                placeholder=" Enter your message"
                className=" w-100 mt-1 profile-edit"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <button className="btn2 w-100 mt-2 py-2">Send Message</button>
          </div>
          {/* community guidlines  */}
          <div className="my-4">
            <div className="d-flex align-items-center gap-2 ms-2 mb-2 border-bottom-gray pb-2">
              <PiDeviceMobileLight size={20} className="icon" />
              <label class="form-label fw-semibold m-0 ">
                {" "}
                Community Guidelines
              </label>
            </div>
            <div className="mt-4">
              <h5 className="primary-color"> Respect Others</h5>
              <ul>
                <li>
                  {" "}
                  Engage with other members respectfully and courteously_ Avoid
                  personal attacks and derogatory comments
                </li>
              </ul>
            </div>
            <div className="mt-3">
              <h5 className="primary-color"> No Harassment</h5>
              <ul>
                <li>
                  Harassment of any kind, including but not limited to bullying,
                  threats, and intimidation, will not be tolerated.
                </li>
              </ul>
            </div>{" "}
            <div className="mt-3">
              <h5 className="primary-color"> Appropriate Content</h5>
              <ul>
                <li>
                  Ensure that all content shared adheres to our content
                  policies. Explicit or offensive material is prohibited
                </li>
              </ul>
            </div>{" "}
            <div className="mt-3">
              <h5 className="primary-color"> Privacy and Safety</h5>
              <ul>
                <li>
                  Do not share personal information or sensitive data. Respect
                  others' privacy and follow safety protocols
                </li>
              </ul>
            </div>{" "}
            <div className="mt-3">
              <h5 className="primary-color">Report Violations</h5>
              <ul>
                <li>
                  If you encounter any behavior or content that violates these
                  guidelines, report it immediately to our support team.
                </li>
              </ul>
            </div>
          </div>
          {/* report a problem  */}
          <div>
            <div className="d-flex align-items-center gap-2 ms-2 mb-2 border-bottom-gray pb-2">
              <PiBellRinging size={20} className="icon" />
              <label class="form-label fw-semibold m-0 ">
                {" "}
                Report a problem
              </label>
            </div>
            <div class="mb-4 w-100 mt-4">
              <div className="d-flex align-items-center gap-2 ms-2">
                <SlPhone />
                <label
                  for="exampleFormControlInput1"
                  class="form-label small-text "
                >
                  Contact Number
                </label>
              </div>

              <input
                type="text"
                className="  w-100 mt-1 profile-edit"
                id="exampleFormControlInput1"
                placeholder=" Enter your contact number"
              />
            </div>{" "}
            <div class="mb-4 w-100 mt-4">
              <div className="d-flex align-items-center gap-2 ms-2">
                <SlPhone />
                <label
                  for="exampleFormControlInput1"
                  class="form-label small-text "
                >
                  Problem type
                </label>
              </div>
              <div className="search-box w-100 d-md-flex align-items-center mt-1 profile-edit">
                <label className="cursor-pointer  w-100 ">
                  Select Problem type
                </label>
                <MdKeyboardArrowDown
                  size={18}
                  className="icon cursor-pointer"
                />
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center gap-2 ms-2">
                <GoPencil />

                <label
                  for="exampleFormControlTextarea1"
                  class="form-label small-text "
                >
                  Report a problem{" "}
                </label>
              </div>

              <textarea
                placeholder=" Enter your message"
                className=" w-100 mt-1 profile-edit"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
            <button className="btn2 w-100 mt-2 py-2">Send Message</button>
          </div>
        </div>
      </div>
    </SettingLayout>
  );
};

export default HelpCenter;
